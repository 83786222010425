import { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Media, Table } from 'reactstrap';
import { Breadcrumbs, Image } from '../../../AbstractElements';
import HeaderCard from '../../Common/Component/HeaderCard';
import CustomizerContext from '../../../_helper/Customizer';
import { CustomerApi, SalesLinkApi} from '../../../api';
import useAxiosPrivate from '../../../Hooks/useAxiosPrivate';
import { toast } from 'react-toastify';
import DataTableServerFetchComponent from '../../Common/Component/DataTableServerFetchComponent';
import ViewModal from '../../Common/Component/ViewModal';
import { ContactTypeVal } from '../../../Data/FormField/PublicData';
import noimage from '../../../assets/images/no-image.png';
import { BranchContactID, Capacity, ContactID, ContactType, DateCreated, DateModified, EmailAddress, FullName, InterfaceIpAddress, LinkType, Pop, Product, RadioIpAddress, SubBusinessSegment, View } from '../../../Constant';
import { formatDateWithTimeZone } from '../../../_helper/dateHelper';
import useAuth from '../../../Hooks/useAuth';

const SalesLinkList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate();
  const { userOrganisationId } = useAuth();
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const [formFields, setFormFields] = useState();
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const getAllLinksData = async (page = 1) => {
      try {
        setIsLoading(true);
        await axiosPrivate.get(`${SalesLinkApi}?organisation_id=${userOrganisationId}&page=${page}&size=${perPage}`).then((resp) => {
          setAllData(resp.data.items);
          setTotalRows(resp.data.total);
        });
      } catch (error) {
          toast.error(error.response.data.detail)
      } finally {
        setIsLoading(false);
      }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllLinksData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };


  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      email: editRecord.email,
      //password: 'Password#123',
      is_active: editRecord.is_active,
      contact: {
          
          contact_type: editRecord.contact_type_id,
          salutation_id: editRecord.salutation_id,
          last_name: editRecord.last_name,
          first_name: editRecord.first_name,
          middle_name: editRecord.middle_name,
          maiden_name: editRecord.maiden_name,
          gender_id: editRecord.gender_id,
          marital_status_id: editRecord.marital_status_id,
          phone_number: editRecord.phone_number,
          nin: editRecord.nin,
          date_of_birth: editRecord.dob ? editRecord.dob : "0000-00-00",
          nationality_ward_id: editRecord.ward_id,
          ward_id: editRecord.address_ward_id,
          //company_email_address: editRecord.email,
          company_alt_phone_number: editRecord.alt_phone_number,
          company_website: editRecord.website,
          street_number: editRecord.street_number,
          street_name: editRecord.street_name,
          nearest_landmark: editRecord.nearest_landmark,
          city: editRecord.city,
          geo_tag: editRecord.geo_tag,
      }
    };
    
    try {
        await axiosPrivate.put(`${CustomerApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        }
        else {
          toast.error('Form submission error');
        }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const addBtn = "";
  
  const tableColumns = [
    /* {
      name: `${FullName}`,
      selector: row => row.contact.last_name,
      sortable: true,
      center: false,
      cell: row => (
                      <>
                        <Media className='d-flex'><Image attrImage={{ className: 'rounded-circle img-30 me-3', src: `${noimage}`, alt: '' }} />
                          <Media body className="align-self-center">
                              <div>
                                {row.contact.contact_type === ContactTypeVal.Individual ? 
                                  `${row.contact.last_name} ${row.contact.first_name} ${row.contact.middle_name}` : 
                                  `${row.contact.company_name}`
                                }
                                </div>
                          </Media>
                        </Media>
                      </>
                    )
    }, */
    {
      name: `${Pop}`,
      selector: row => row.link_technical_detail.location.name,
      sortable: true,
      center: false,
    },
    {
      name: `${LinkType}`,
      selector: row => row.link_technical_detail.link_type.name,
      sortable: true,
      center: false,
    },
    {
      name: `${BranchContactID}`,
      selector: row => row.contact_branch.contact_number,
      sortable: true,
      center: false,
    },
    {
      name: `${Product}`,
      selector: row => row.product?.name,
      sortable: true,
      center: false,
    },
    /* {
      name: `${Status}`,
      selector: row => row.user_login.is_active,
      sortable: true,
      center: false,
      cell: row => (
        <>
          {row.user_login.is_active ? (
            <span className="badge badge-light-success">Active</span>
          ) : (
            <span className="badge badge-light-danger">Inactive</span>
          )}
        </>
      ),
    }, */
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  const customerViewAction = [
    {
      label: `${View}`,
      action: handleView,
      color: 'success',
      icon: 'icon-eye',
    },
  ];

  const handlePageChange = page => {
    getAllLinksData(page)
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true);
    const response = await axiosPrivate.get(`${SalesLinkApi}?organisation_id=${userOrganisationId}&page=${page}&size=${perPage}`);
    setAllData(response.data.items);
    setPerPage(newPerPage);
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Breadcrumbs parent="Sales" title="Links" mainTitle="Links" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="Link List" btnUrl={addBtn} />
              <CardBody>
                <DataTableServerFetchComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  //onView={handleView}
                  //onDelete={handleDelete}
                  //onEdit={handleEdit}
                  useTableAction={customerViewAction}

                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Pop}</th>
                    <td>{selectedRowData.link_technical_detail.location.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{LinkType}</th>
                    <td>{selectedRowData.link_technical_detail.link_type.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{RadioIpAddress}</th>
                    <td>{selectedRowData.link_technical_detail.ip_address}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{InterfaceIpAddress}</th>
                    <td>{selectedRowData.link_technical_detail.interface_ip_address}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{FullName}</th>
                    <td>
                      {selectedRowData.contact.contact_type === ContactTypeVal.Individual ? 
                        `${selectedRowData.contact.last_name} ${selectedRowData.contact.first_name} ${selectedRowData.contact.middle_name}` : 
                        `${selectedRowData.contact.company_name}`
                      }
                    </td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{ContactID}</th>
                    <td>{selectedRowData.contact.contact_number}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{BranchContactID}</th>
                    <td>{selectedRowData.contact_branch.contact_number}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Product}</th>
                    <td>{selectedRowData.product?.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Capacity}</th>
                    <td>{selectedRowData.product?.capacity?.capacity} {selectedRowData.product?.capacity?.capacity_type?.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{SubBusinessSegment}</th>
                    <td>{selectedRowData.product?.business_sub_segment?.name}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}
          
        </ViewModal>
      </Container>
    </Fragment>
  );
};

export default SalesLinkList;