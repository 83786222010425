import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import { ExpenseGroup, Description, GroupName } from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import { RegionalContinentApi } from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

export default function useExpenseGroupController() {
    const axiosPrivate = useAxiosPrivate()
    const formTitle = ExpenseGroup;

    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "group_name",
            name: "group_name",
            label: GroupName,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },
    ]);

    const expenseCategory = createDropdownField(axiosPrivate,
        "expense_category",
        "expense_category",
        ExpenseGroup,
        `${RegionalContinentApi}`,
        "",
        6
    );

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [...formFields, expenseCategory];
        await expenseCategory.fetchOptions();
        setFormFields(updatedFields);
    }

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/expense-group/${layoutURL}`;

    function onSubmit(data: any) {
        //console.log(data);
    }

    return { formTitle, formFields, backBtn, onSubmit };
}
