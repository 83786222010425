import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
    RegionalContinentApi,
    RegionalCountryApi,
    ZoneApi,
} from "../../../../../api";
import {
    Contacts,
    Description,
    GeneralLedgerClass,
    GeneralLedgerGroup,
    GeneralLedgerType,
    Name,
    Status,
    SubLedgerAccount,
    SubLedgerClass,
    SubLedgerType,
} from "../../../../../Constant";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

export default function useSubLedgerAccountController() {
    const axiosPrivate = useAxiosPrivate()
    const formTitle = SubLedgerAccount;

    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "name",
            name: "name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "status",
            name: "status",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    //?? DROPDOWNS INITS >

    const generalLedgerGroupDropDown = createDropdownField(axiosPrivate,
        "general_ledger_group",
        "general_ledger_group",
        GeneralLedgerGroup,
        `${RegionalContinentApi}`,
        "",
        6
    );
    generalLedgerGroupDropDown.onChange = async (id, fields) => {
        await generalLedgerClassDropDown.fetchOptions();
        updateFields();
    };

    const generalLedgerClassDropDown = createDropdownField(axiosPrivate,
        "general_ledger_class",
        "general_ledger_class",
        GeneralLedgerClass,
        `${RegionalCountryApi}`,
        "",
        6
    );
    generalLedgerClassDropDown.onChange = async (id, fields) => {
        await generalLedgerTypeDropDown.fetchOptions();
        updateFields();
    };

    const generalLedgerTypeDropDown = createDropdownField(axiosPrivate,
        "general_ledger_type",
        "general_ledger_type",
        GeneralLedgerType,
        `${ZoneApi}`,
        "",
        6
    );
    generalLedgerTypeDropDown.onChange = async (id, fields) => {
        await subLedgerClassDropDown.fetchOptions();
        updateFields();
    };

    const subLedgerClassDropDown = createDropdownField(axiosPrivate,
        "sub_ledger_class",
        "sub_ledger_class",
        SubLedgerClass,
        `${RegionalContinentApi}`,
        "",
        6
    );
    subLedgerClassDropDown.onChange = async (id, fields) => {
        await subLedgerTypeDropDown.fetchOptions();
        updateFields();
    };

    const subLedgerTypeDropDown = createDropdownField(axiosPrivate,
        "sub_ledger_type",
        "sub_ledger_type",
        SubLedgerType,
        `${RegionalContinentApi}`,
        "",
        6
    );

    const contactsDropDown = createDropdownField(axiosPrivate,
        "contacts",
        "contacts",
        Contacts,
        `${RegionalContinentApi}`,
        "",
        6
    );

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [
            ...formFields,
            generalLedgerGroupDropDown,
            generalLedgerClassDropDown,
            generalLedgerTypeDropDown,
            subLedgerClassDropDown,
            subLedgerTypeDropDown,
            contactsDropDown,
        ];
        await generalLedgerGroupDropDown.fetchOptions();
        await contactsDropDown.fetchOptions();
        setFormFields(updatedFields);
    }

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-account/${layoutURL}`;

    function onSubmit(data: any) {
       // console.log(data);
    }

    return { formTitle, formFields, backBtn, onSubmit };
}
