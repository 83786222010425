import { useContext, useMemo, useState } from "react";
import dataJson from "./sub-ledger-account-data.json";
import { StatusRow } from "./SubLedgerAccountList";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    Contacts,
    Description,
    Edit,
    GeneralLedgerClass,
    GeneralLedgerGroup,
    GeneralLedgerType,
    Name,
    Status,
    SubLedgerAccount,
    SubLedgerClass,
    SubLedgerType,
} from "../../../../../Constant";
import useSubLedgerAccountController from "../Form/SubLedgerAccount.controller";
import { useNavigate } from "react-router";

export default function useGroupGeneralLedgerTypeListController() {
    const title = SubLedgerAccount;
    const navigate = useNavigate();
    const { formFields } = useSubLedgerAccountController();
    const tableColumns = [
        {
            name: Name,
            selector: (row: (typeof data)[0]) => `${row.name}`,
            sortable: true,
            center: false,
        },

        {
            name: Description,
            selector: (row: (typeof data)[0]) => `${row.description}`,
            sortable: false,
            center: false,
        },

        {
            name: Status,
            selector: (row: (typeof data)[0]) => `${row.status}`,
            sortable: true,
            center: true,
            cell: (row: (typeof data)[0]) => StatusRow(row),
        },

        {
            name: GeneralLedgerGroup,
            selector: (row: (typeof data)[0]) =>
                `${row.sub_ledger_type.sub_ledger_class.general_ledger_type.general_ledger_class.general_ledger_group.name}`,
            sortable: true,
            center: false,
        },

        {
            name: GeneralLedgerClass,
            selector: (row: (typeof data)[0]) =>
                `${row.sub_ledger_type.sub_ledger_class.general_ledger_type.general_ledger_class.name}`,
            sortable: true,
            center: false,
        },

        {
            name: GeneralLedgerType,
            selector: (row: (typeof data)[0]) =>
                `${row.sub_ledger_type.sub_ledger_class.general_ledger_type.name}`,
            sortable: true,
            center: false,
        },

        {
            name: SubLedgerClass,
            selector: (row: (typeof data)[0]) =>
                `${row.sub_ledger_type.sub_ledger_class.name}`,
            sortable: true,
            center: false,
        },

        {
            name: SubLedgerType,
            selector: (row: (typeof data)[0]) => `${row.sub_ledger_type.name}`,
            sortable: true,
            center: false,
        },

        {
            name: Contacts,
            selector: (row: (typeof data)[0]) => `${row.contacts}`,
            sortable: true,
            center: false,
        },
    ];

    const { layoutURL } = useContext(CustomizerContext);
    const newBtn = `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-account/create/${layoutURL}`;
    const gotoEdit = (row: any) => {
        navigate(
            `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-account/edit/${row.id}/${layoutURL}`
        );
    };

    // const [isLoading, setIsLoading] = useState(true);
    const [data] = useState(dataJson);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<
        (typeof data)[0] | null
    >(null);
    const [editMode, setEditMode] = useState(false);

    const addToDefaultAction = [
        {
            label: `${Edit}`,
            action: gotoEdit,
            color: "info",
            icon: "icon-user",
        },
    ];

    const handleEdit = (row: (typeof data)[0]) => {
        setViewModalOpen(true);
        setEditMode(true);
        setSelectedRowData(row);

        const GLG_INDEX = formFields.findIndex(
            (val) => val.id === "general_ledger_group"
        );
        
        // row.regional_state_id = row.lga.regional_state_id;
        // formFields[GLG_INDEX].onChange("general_ledger_group", formFields);
        setSelectedRowData(row);
    };

    const handleView = (row: (typeof data)[0]) => {
        setViewModalOpen(true);
        setEditMode(false);
        setSelectedRowData(row);
    };

    const handleEditRec = async (
        id: number | string,
        editRecord: (typeof data)[0]
    ) => {
       
    };

    const memoizedHandleEdit = useMemo(
        () => handleEditRec,
        [data, selectedRowData]
    );

    return {
        data,
        tableColumns,
        viewModalOpen,
        selectedRowData,
        editMode,
        newBtn,
        title,
        formFields,
        addToDefaultAction,
        setViewModalOpen,
        handleView,
        handleEdit,
        memoizedHandleEdit,
    };
}
