import { useContext, useEffect, useState } from "react";
import {
    Name,
    Description,
    Status,
    SubLedgerClass,
    Contacts,
    GeneralLedgerClass,
    GeneralLedgerGroup,
    GeneralLedgerType,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import { RegionalContinentApi } from "../../../../../api";
import CustomizerContext from "../../../../../_helper/Customizer";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

export default function useSubLedgerClassController() {
    const axiosPrivate = useAxiosPrivate()
    const formTitle = SubLedgerClass;
    const [formFields, setFormFields] = useState<any>([
        {
            id: "name",
            name: "name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "status",
            name: "status",
            label: Status,
            type: "switch",
            value: "1",
            colSize: 6,
        },
    ]);

    //!! DROPDOWNS INITS >
    const generalLedgerGroupDropDown = createDropdownField(axiosPrivate,
        "general_ledger_group",
        "general_ledger_group",
        GeneralLedgerGroup,
        `${RegionalContinentApi}`,
        "",
        6
    );
    generalLedgerGroupDropDown.onChange = async (id, fields) => {
        await generalLedgerClassDropDown.fetchOptions();
        updateFields();
    };

    const generalLedgerClassDropDown = createDropdownField(axiosPrivate,
        "general_ledger_class",
        "general_ledger_class",
        GeneralLedgerClass,
        `${RegionalContinentApi}`,
        "",
        6
    );
    generalLedgerClassDropDown.onChange = async (id, fields) => {
        await generalLedgerTypeDropDown.fetchOptions();
        updateFields();
    };

    const generalLedgerTypeDropDown = createDropdownField(axiosPrivate,
        "general_ledger_type",
        "general_ledger_type",
        GeneralLedgerType,
        `${RegionalContinentApi}`,
        "",
        6
    );

    const contactsDropDown = createDropdownField(axiosPrivate,
        "contacts",
        "contacts",
        Contacts,
        `${RegionalContinentApi}`,
        "",
        6
    );

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [
            ...formFields,
            generalLedgerGroupDropDown,
            generalLedgerClassDropDown,
            generalLedgerTypeDropDown,
            contactsDropDown,
        ];

        await generalLedgerGroupDropDown.fetchOptions();
        await contactsDropDown.fetchOptions();
        setFormFields(updatedFields);
    }

    useEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-class/${layoutURL}`;

    function onSubmit(data: any) {
        
    }

    return { formTitle, formFields, backBtn, onSubmit };
}
