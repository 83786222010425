import { Fragment, useContext, useState, useEffect, useLayoutEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Breadcrumbs from "../../../../CommonElements/Breadcrumbs"
import CustomizerContext from '../../../../_helper/Customizer'; 
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CaseApi, CaseGroupApi, CaseTypeApi, CustomerApi, CustomerLinkApi, PriorityApi, ResolutionStatusApi, } from "../../../../api"
import { toast } from 'react-toastify';
import CardRibbonWithForm from '../../../Common/Component/CardRibbonWithForm'; 
import { case_form_fields } from './Case';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate'; 
import { createDropdownField } from '../../../../Data/FormField/PublicData';
import { CaseGroup, CaseTypeName, Contact, Link, Priority, ResolutionStatus } from "../../../../Constant"
import useAuth from '../../../../Hooks/useAuth';

const CaseCreate = () => {
  const history = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const lists = `${process.env.PUBLIC_URL}/crm/case-management/case/${layoutURL}`;

  const { formState: { errors },} = useForm(); 

  const [formFields, setFormFields] = useState(case_form_fields);
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const caseGroupDropdownField = createDropdownField(axiosPrivate,
    'case_group_id',
    'case_group_id',
    `${CaseGroup}`,
    `${CaseGroupApi}?organisation_id=${userOrganisationId}&service_type_id=1`,
    '', 6
  );

  const caseTypeDropdownField = createDropdownField(axiosPrivate,
    'case_type_id',
    'case_type_id',
    `${CaseTypeName}`,
    `${CaseTypeApi}?organisation_id=${userOrganisationId}`,
    '', 6
  );

  const customerDropdownField2 = createDropdownField(axiosPrivate,
    'contact_id',
    'contact_id',
    `${Contact}`,
    `${CustomerApi}?organisation_id=${userOrganisationId}`,
    '', 6, '', 'last_name, first_name, middle_name'
  );

  const customerDropdownField = {
    name: "contact_id",
    id: "contact_number, company_name, last_name, first_name",
    api: `${CustomerApi}`,
    label: `${Contact}`,
    type: "input-select",
    value: "",
    colSize: 6,
    required: true,
    onSelectedChange : async function (selectedId, updatedFields) { 
      const linkField = updatedFields.find((field) => field.id === 'link_id');
      
      if (linkField) {
        const endPoint = `${CustomerLinkApi}?organisation_id=${userOrganisationId}&contact_id=${selectedId}`
        await linkField.fetchOptions(selectedId, false, endPoint);
        setFormFields([...updatedFields]);
      } 
    }
  };

  const linkDropdownField = createDropdownField(axiosPrivate,
    'link_id',
    'link_id',
    `${Link}`,
    ``,
    '', 6, true, 
    //'contact.branch.contact_number, contact.branch.name, contact.branch.street_number, contact.branch.street_name'
    //'contact.branch.contact_number, contact.branch.name'
    //'contact_branch.contact_number, contact_branch.name, product.name | contact.contact_number, contact.company_name, product.name'
    //'contact_branch.contact_number, contact_branch.name | contact.contact_number, contact.last_name | contact.contact_number, contact.first_name, contact.middle_name, [product.name]'
    "contact_branch.contact_number, contact_branch.name | contact.contact_number, contact.company_name | contact.contact_number, contact.last_name, contact.first_name | [product.name]"
  );

  const Prioritydropdown = createDropdownField(axiosPrivate,
    'priority_id',
    'priority_id',
    `${Priority}`,
    `${PriorityApi}?organisation_id=${userOrganisationId}`,
    '', 6
  );

  const ResolutionStatusdropdown = createDropdownField(axiosPrivate,
    'resolution_status_id',
    'resolution_status_id',
    `${ResolutionStatus}`,
    `${ResolutionStatusApi}?organisation_id=${userOrganisationId}`,
    '', 6
  );

  useLayoutEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const updatedFields = [caseGroupDropdownField, 
            caseTypeDropdownField, 
            customerDropdownField, 
            linkDropdownField, 
            Prioritydropdown, 
            ResolutionStatusdropdown, 
            ...formFields];
          //const indexOfName = updatedFields.findIndex((formField) => formField.id === 'case_Category_id');

          await updatedFields[0].fetchOptions();
          //await customerDropdownField.fetchOptions();
          await Prioritydropdown.fetchOptions();
          await ResolutionStatusdropdown.fetchOptions();

          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);

          /* const customerField = updatedFields.find((field) => field.name === 'contact_id');
          customerField.onSelectedChange = async (selectedId, updatedFields) => { 
            const linkField = updatedFields.find((field) => field.id === 'link_id');
            
            if (linkField) {
              const endPoint = `${CustomerLinkApi}?organisation_id=${userOrganisationId}&contact_id=${selectedId}`
              await linkField.fetchOptions(selectedId, false, endPoint);
              setFormFields([...updatedFields]);
            } 
          }; */
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
      
    };

    fetchData();

  }, [formFields, isNewFieldAdded, caseGroupDropdownField, caseTypeDropdownField, customerDropdownField]);

  caseGroupDropdownField.onChange = async (selectedCaseGroupId, updatedFields) => { 
    const caseTypeField = updatedFields.find((field) => field.id === 'case_type_id');
    
    if (caseTypeField) {
      const endPoint = `${CaseTypeApi}?organisation_id=${userOrganisationId}&case_group_id=${selectedCaseGroupId}`
      await caseTypeField.fetchOptions(selectedCaseGroupId, false, endPoint);
      setFormFields([...updatedFields]);
    } 
  };

  const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
        errors.showMessages();
      }
      
      const newRecordTemp = {
        case_type_id: newRecord.case_type_id,
        contact_id: newRecord.contact_id,
        link_id: newRecord.link_id,
        priority_id: newRecord.priority_id,
        resolution_status_id: newRecord.resolution_status_id,
        subject: newRecord.subject,
        message: newRecord.message,
        due_date: newRecord.due_date,
       };

      try {
          await axiosPrivate.post(CaseApi, newRecordTemp).then((resp) => {
            toast.success(`${resp.data.track_number} is successfully added.`)
            history(lists);
          });
      } catch (error) {
        if (error.response && error.response.status === 422) {
          toast.error('Validation Errors');
        } else if (error.response && error.response.status === 400) {
          toast.error(error.response.data.detail);
        } else {
          toast.error('Form submission error');
        }
        throw error;
    }   
  };


  return (
    <Fragment>
      <Breadcrumbs parent="settings" title="Case" mainTitle="Case" />
      <Container fluid={true}>
        <Row>
          <Col sm='12'>
            <CardRibbonWithForm formFields={formFields} onSubmit={addNewRecord} ribbonUrl={lists} />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CaseCreate;