
import { useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {Card} from 'reactstrap';
import {CardBody} from 'reactstrap';
import { BackToList, SubBusinessSegment, Unit } from '../../../../Constant';
import { Product, Capacity, ProductClass, ProductGroup, ProductType } from '../../../../Constant';
import GenerateForm from '../../../Common/Component/GenerateForm';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import CustomizerContext from '../../../../_helper/Customizer';
import { Breadcrumbs } from '../../../../AbstractElements';
import { product_form_fields } from './Product';
import { createDropdownField } from '../../../../Data/FormField/PublicData';
import { ProductGroupApi, ProductTypeApi, CapacityApi, ProductClassApi, ProductApi, UnitApi, SubBusinessSegmentApi } from '../../../../api';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import useAuth from '../../../../Hooks/useAuth';


function ProductCreate() {
    const history = useNavigate();
    const axiosPrivate = useAxiosPrivate()
    const { userOrganisationId } = useAuth();
    const { layoutURL } = useContext(CustomizerContext);
    const [formFields, setFormFields] = useState(product_form_fields(`${Product}`, 6));
    const lists = `${process.env.PUBLIC_URL}/sales/settings/products/${layoutURL}`;
    const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

    const { formState: { errors },} = useForm();

    const addNewRecord =  async (newRecord) => {
      if (newRecord === '') { 
          errors.showMessages();
      }

      const newRecordTemp = {
          product_type_id: newRecord.product_type_id,
          unit_id: newRecord.unit_id,
          business_sub_segment_id: newRecord.business_sub_segment_id,
          name: newRecord.name,
          description: newRecord.description,
          capacity_id: newRecord.capacity_id,
          tariff_price: newRecord.tariff_price,
          base_price: newRecord.tariff_price,
          is_active: newRecord.is_active,
          //product_image: newRecord.file[0]
      };

      try {
          await axiosPrivate.post(ProductApi, newRecordTemp, {
              headers: {
                  'Content-Type': 'application/json',
              },
          }).then((resp) => {
              toast.success(`${resp.data.name} is successfully added.`)
              history(lists);
          });
      } catch (error) {
          if (error.response && error.response.status === 422) {
              toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
              toast.error(error.response.data.detail);
          }
          else {
              toast.error('Form submission error');
          }
          throw error;
      }  
    };

    const productGroupDropdownField = createDropdownField(axiosPrivate,
        'product_group_id',
        'product_group_id',
        `${ProductGroup}`,
        `${ProductGroupApi}?organisation_id=${userOrganisationId}`,
        '',
        6
    );

    const productClassDropdownField = createDropdownField(axiosPrivate,
        'product_class_id',
        'product_class_id',
        `${ProductClass}`,
        '',
        '',
        6
    );

    const productTypeDropdownField = createDropdownField(axiosPrivate,
        'product_type_id',
        'product_type_id',
        `${ProductType}`,
        `${ProductTypeApi}?organisation_id=${userOrganisationId}`,
        '',
        6
    );

    const capacityDropdownField = createDropdownField(axiosPrivate,
        'capacity_id',
        'capacity_id',
        `${Capacity}`,
        `${CapacityApi}?organisation_id=${userOrganisationId}`,
        '',
        6, '', 'capacity, capacity_type.name'
    );

    const unitDropdownField = createDropdownField(axiosPrivate,
        'unit_id',
        'unit_id',
        `${Unit}`,
        `${UnitApi(userOrganisationId)}`,
        '',
        6
    );

    const bizSubSegmentDropdownField = createDropdownField(axiosPrivate,
        'business_sub_segment_id',
        'business_sub_segment_id',
        `${SubBusinessSegment}`,
        `${SubBusinessSegmentApi(userOrganisationId)}`,
        '',
        6
    );

    useEffect(() => {

        const fetchData = async () => {
            try {
                if (!isNewFieldAdded) {
                
                const updatedFields = [productGroupDropdownField, 
                    productClassDropdownField, 
                    productTypeDropdownField, 
                    capacityDropdownField,  
                    ...formFields,
                    unitDropdownField,
                    bizSubSegmentDropdownField
                ];
        
                await updatedFields[0].fetchOptions();
                await productClassDropdownField.fetchOptions();
                await productTypeDropdownField.fetchOptions();
                await capacityDropdownField.fetchOptions();
                await unitDropdownField.fetchOptions();
                await bizSubSegmentDropdownField.fetchOptions();
                
                
                setFormFields(updatedFields);
                setIsNewFieldAdded(true);
                }
            } catch (error) {
                //console.error('Error fetching options:', error);
            }
        };
    
        fetchData();
    }, [formFields, isNewFieldAdded, productGroupDropdownField]); 

    productGroupDropdownField.onChange = async (selectedPGId, updatedFields) => { 
        const PCField = updatedFields.find((field) => field.id === 'product_class_id');
        
        if (PCField) {
          const endPoint = `${ProductClassApi}?organisation_id=${userOrganisationId}&product_group_id=${selectedPGId}`
          await PCField.fetchOptions(selectedPGId, false, endPoint);
          setFormFields([...updatedFields]);
        } 
    };

    return (

        <>

            <Fragment>
                <Breadcrumbs parent="Sales" title="Product" mainTitle="Product" />
                <Card className='ribbon-wrapper-right b-l-secondary border-2'>
                    <CardBody>
                        <Link to={lists}>
                            <div className="ribbon ribbon-clip-right ribbon-right ribbon-primary">
                                {BackToList}
                            </div>
                        </Link>

                        <GenerateForm formFields={formFields} onSubmit={addNewRecord} listUrl={lists} formTitle={"Product"} onEdit={undefined} editRecord={undefined} />

                    </CardBody>
                </Card>
            </Fragment>


        </>

    )
}

export default ProductCreate