import React from "react";
import { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Loader from "../Layout/Loader";
import { authRoutes } from "./AuthRoutes";
import LayoutRoutes from "../Route/LayoutRoutes";
import Signin from "../Auth/Signin";
import PrivateRoute from "./PrivateRoute";
import { classes } from "../Data/Layouts";
import useAuth from "../Hooks/useAuth";
import PersistLogin from "../Components/PersistLogin";
import Unauthorized from "../Components/Unauthorized";
import Error404 from "../Components/Pages/ErrorPages/ErrorPage404";

const Routers = () => {
  const { auth } = useAuth();
  //const login = useState(JSON.parse(localStorage.getItem("login")))[0];
  const [authenticated, setAuthenticated] = useState(false);
  const defaultLayoutObj = classes.find((item) => Object.values(item).pop(1) === "compact-wrapper");
  const layout = localStorage.getItem("layout") || Object.keys(defaultLayoutObj).pop();
  
  useEffect(() => {
    
    let abortController = new AbortController();
    setAuthenticated(JSON.parse(localStorage.getItem("authenticated")));
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <BrowserRouter basename={"/"}>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route element={<PersistLogin />}>
            <Route path={"/"} element={<PrivateRoute />}>
                {auth?.user || authenticated ? (
                  <>
                    <Route exact path={`${process.env.PUBLIC_URL}`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default/${layout}`} />} />
                    <Route exact path={`/`} element={<Navigate to={`${process.env.PUBLIC_URL}/dashboard/default/${layout}`} />} />
                  </>
                ) : (
                  ""
                )} 
                <Route path={`/*`} element={<LayoutRoutes authPermissions={auth?.permissions} />} />
              </Route>
            </Route>

            <Route exact path={`${process.env.PUBLIC_URL}/login`} element={<Signin />} />
            {authRoutes.map(({ path, Component }, i) => (
              <Route path={path} element={Component} key={i} />
            ))}

            {/* catch all */}
            <Route path={`${process.env.PUBLIC_URL}/unathorised`} element={<Unauthorized />} />
            <Route path="*" element={<Error404 />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default Routers;
