import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    Name,
    ExpenseCategory,
    ShortName,
    Description,
} from "../../../../../Constant";

export default function useExpenseCategoryController() {
    const formTitle = ExpenseCategory;

    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "category_name",
            name: "category_name",
            label: Name,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "short_name",
            name: "short_name",
            label: ShortName,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },
    ]);

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [...formFields];
        setFormFields(updatedFields);
    }

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/expense-category/${layoutURL}`;

    function onSubmit(data: any) {
        //console.log(data);
    }

    return { formTitle, formFields, backBtn, onSubmit };
}
