import React, { Fragment } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import useGeneralLedgerGroupListController from "./GeneralLedgerGroupList.controller";
import data from "./general-ledger-group-data.json";
import { Breadcrumbs } from "../../../../../AbstractElements";
import HeaderCard from "../../../../Common/Component/HeaderCard";
import DataTableComponent from "../../../../Common/Component/DataTableComponent";
import ViewModal from "../../../../Common/Component/ViewModal";
import { generalLedgerGroupForm } from "../Form/GeneralLedgerGroup.controller";
// import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";

const GeneralLedgerGroupList = () => {
    const {
        data,
        tableColumns,
        newBtn,
        editMode,
        selectedRowData,
        setViewModalOpen,
        viewModalOpen,
        handleView,
        handleEdit,
        memoizedHandleEdit,
    } = useGeneralLedgerGroupListController();

    return (
        <Fragment>
            <Breadcrumbs
                parent="Settings"
                title="General Ledger Group"
                mainTitle="General Ledger Group"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard
                                title="General Ledger Group List"
                                btnUrl={newBtn}
                                span1={undefined}
                                span2={undefined}
                                mainClasses={undefined}
                            />
                            <CardBody>
                                <DataTableComponent
                                    tabledata={data}
                                    tableColumns={tableColumns}
                                    isLoading={false}
                                    onView={handleView}
                                    // onDelete={handleDelete}
                                    onEdit={handleEdit}
                                    useTableAction={undefined}
                                    addToDefaultAction={undefined}
                                    onDelete={undefined}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <ViewModal
                    isOpen={viewModalOpen}
                    toggle={() => setViewModalOpen(!viewModalOpen)}
                    rowData={selectedRowData}
                    formFields={generalLedgerGroupForm}
                    editMode={editMode}
                    onEdit={memoizedHandleEdit}
                    onSubmit={undefined}
                    modalTitle={undefined}
                >
                    {selectedRowData && (
                        <>
                            <Table>
                                <tbody>
                                    <tr className="border-bottom-primary">
                                        <th scope="row">Group Name</th>
                                        <td>{selectedRowData.group_name}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">Balance Ledger</th>
                                        <td>
                                            {selectedRowData.balance_ledger}
                                        </td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">Status</th>
                                        <td>
                                            {selectedRowData.status ? (
                                                <span className="badge badge-light-success">
                                                    Active
                                                </span>
                                            ) : (
                                                <span className="badge badge-light-danger">
                                                    Inactive
                                                </span>
                                            )}
                                        </td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">Report</th>
                                        <td>{selectedRowData.report}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">Description</th>
                                        <td>{selectedRowData.description}</td>
                                    </tr>

                                    {/* <tr className="border-bottom-secondary">
                                        <th scope="row">Date Created</th>
                                        <td>
                                            {formatDateWithTimeZone(
                                                selectedRowData.created_at
                                            )}
                                        </td>
                                    </tr> */}
                                </tbody>
                            </Table>
                        </>
                    )}
                </ViewModal>
            </Container>
        </Fragment>
    );
};

export default GeneralLedgerGroupList;

export const StatusRow = (row: (typeof data)[0]) => {
    return row.status ? (
        <span className="badge badge-light-success">Active</span>
    ) : (
        <span className="badge badge-light-danger">Inactive</span>
    );
};
