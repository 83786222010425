import { useContext, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import axios from "../../../../../api/axios";

export const generalLedgerGroupForm = [
    {
        id: "group_name",
        name: "group_name",
        label: `GROUP NAME`,
        type: "text",
        value: "",
        required: true,
        colSize: 6,
    },

    {
        id: "balance_ledger",
        name: "balance_ledger",
        label: `BALANCE LEDGER`,
        type: "select",
        required: true,
        colSize: 6,
        options: [
            { label: "Debit", value: "debit" },
            { label: "Credit", value: "credit" },
        ],
        async fetchOptions() {
            try {
                const response = await axios.get("/");
                this.options = response.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
            } catch (error) {
                console.error("Error fetching sector options:", error);
            }
        },
        onChange: (value: any) => {}
            //console.log(value, "LOGGED FROM THE BALANCE LEG"), // handle the change event here
    },

    {
        id: "report",
        name: "report",
        label: `REPORT`,
        type: "select",
        required: true,
        colSize: 6,
        options: [
            { label: "Profit", value: "profit" },
            { label: "Loss", value: "loss" },
        ],
        async fetchOptions() {
            try {
                const response = await axios.get("/");
                this.options = response.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
            } catch (error) {
                console.error("Error fetching sector options:", error);
            }
        },
        onChange: (value: any) => {}, // handle the change event here
    },

    {
        id: "description",
        name: "description",
        label: `DESCRIPTION`,
        type: "textarea",
        value: "",
        required: true,
        colSize: 6,
    },

    {
        id: "status",
        name: "status",
        label: `STATUS`,
        type: "switch",
        value: "1",
        colSize: 6,
    },
];

export default function useGeneralLedgerGroupController() {
    const formTitle = "General Ledger Group";
    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/general-ledger-group/${layoutURL}`;
    const [formFields] = useState(generalLedgerGroupForm);
    function onSubmit(data: any) {
        //console.log(data);
    }

    return { formTitle, formFields, backBtn, onSubmit };
}
