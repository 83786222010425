import { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { Breadcrumbs } from '../../../../AbstractElements';
import { CaseGroup, CaseTypeName, DateCreated, DateModified, DelegationSLA, DeleteSuccess, Description, EscalationExecutiveTeam, EscalationSLA, EscalationTeam, Priority, ResolutionSLA, ServiceTeam, ServiceType, Status, Team, Timeline } from '../../../../Constant';
import HeaderCard from '../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../_helper/Customizer';
import { CaseGroupApi, CaseTypeApi, PriorityApi, ServiceTypeApi, SlaApi, TeamApi } from '../../../../api';
import { toast } from 'react-toastify';
import DataTableServerFetchComponent from '../../../Common/Component/DataTableServerFetchComponent';
import ViewModal from '../../../Common/Component/ViewModal';
import { base_form_fields, createDropdownField } from '../../../../Data/FormField/PublicData';
import useAxiosPrivate from '../../../../Hooks/useAxiosPrivate';
import { CaseCategoryApi } from '../../../../api';
import { formatDateWithTimeZone } from '../../../../_helper/dateHelper';
import useAuth from '../../../../Hooks/useAuth';
import { member_auto_assign_field } from '../../../../Data/FormField/CrmData';

const CaseTypeList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState();
  const [formFields, setFormFields] = useState(base_form_fields(`${CaseTypeName}`, 6));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  
  const serviceTypedropdown = createDropdownField(axiosPrivate,
    'service_type_id',
    'service_type_id',
    `${ServiceType}`,
    `${ServiceTypeApi}`,
    '', 6
  );

  const caseGroupDropdown = createDropdownField(axiosPrivate,
    'case_group_id',
    'case_group_id',
    `${CaseGroup}`,
    `${CaseGroupApi}?organisation_id=${userOrganisationId}`,
    '', 6
  );
  const PriorityCategorydropdown = createDropdownField(axiosPrivate,
    'priority_id',
    'priority_id',
    `${Priority}`,
    `${PriorityApi}?organisation_id=${userOrganisationId}`,
    '', 6
  );
  
  const ResolutionSLAdropdown = createDropdownField(axiosPrivate,
    'sla_id',
    'sla_id',
    `${ResolutionSLA}`,
    ``,
    '', 6
  );

  const DelegationSLAdropdown = createDropdownField(axiosPrivate,
    'delegation_sla_id',
    'delegation_sla_id',
    `${DelegationSLA}`,
    ``,
    '', 6
  );

  const EscalationSLAdropdown = createDropdownField(axiosPrivate,
    'escalation_sla_id',
    'escalation_sla_id',
    `${EscalationSLA}`,
    ``,
    '', 6
  );

  const ServiceTeamDropDown = createDropdownField(axiosPrivate,
    'service_team_id',
    'service_team_id',
    `${ServiceTeam}`,
    ``,
    '', 6
  );
  
  const EscalationTeamDropDown = createDropdownField(axiosPrivate,
    'escalation_team_id',
    'escalation_team_id',
    `${EscalationTeam}`,
    ``,
    '', 6
  );

  const EscalationExecutiveTeamDropDown = createDropdownField(axiosPrivate,
    'escalation_executive_team_id',
    'escalation_executive_team_id',
    `${EscalationExecutiveTeam}`,
    ``,
    '', 6
  );

  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {

          const updatedFields = [serviceTypedropdown, caseGroupDropdown, ...formFields];
          const indexOfName = updatedFields.findIndex((formField) => formField.id === 'description');
          updatedFields.splice(indexOfName + 1, 0, PriorityCategorydropdown, 
            { type: 'group-title', label: `${Team}`},
            ServiceTeamDropDown, EscalationTeamDropDown, EscalationExecutiveTeamDropDown,
            { type: 'group-title', label: `${Timeline}`},
            ResolutionSLAdropdown, DelegationSLAdropdown, EscalationSLAdropdown, 
            member_auto_assign_field(0,0,6));

          await updatedFields[0].fetchOptions();
          await PriorityCategorydropdown.fetchOptions();

          const indexOfServiceTeam = updatedFields.findIndex((updatedField) => updatedField.id === 'service_team_id');
          const indexOfEscalationTeam = updatedFields.findIndex((updatedField) => updatedField.id === 'escalation_team_id');
          const indexOfEscalationExecutiveTeam = updatedFields.findIndex((updatedField) => updatedField.id === 'escalation_executive_team_id');

          try {
            await axiosPrivate.get(`${TeamApi}?organisation_id=${userOrganisationId}`).then((resp) => {
              resp.data.forEach(option => {
                updatedFields[indexOfServiceTeam].options.push({ value: option.id, label: option.name });
                updatedFields[indexOfEscalationTeam].options.push({ value: option.id, label: option.name });
                updatedFields[indexOfEscalationExecutiveTeam].options.push({ value: option.id, label: option.name });
              });
            });
          } catch (error) { }

          const indexOfResolutionSLA = updatedFields.findIndex((updatedField) => updatedField.id === 'sla_id');
          const indexOfDelegationSLA = updatedFields.findIndex((updatedField) => updatedField.id === 'delegation_sla_id');
          const indexOfEscalationSLA = updatedFields.findIndex((updatedField) => updatedField.id === 'escalation_sla_id');
          
          try {
            await axiosPrivate.get(`${SlaApi}?organisation_id=${userOrganisationId}`).then((resp) => {
              resp.data.forEach(option => {
                updatedFields[indexOfResolutionSLA].options.push({ value: option.id, label: option.name });
                updatedFields[indexOfDelegationSLA].options.push({ value: option.id, label: option.name });
                updatedFields[indexOfEscalationSLA].options.push({ value: option.id, label: option.name });
              });
            });
          } catch (error) { }

          setFormFields(updatedFields);
          setIsNewFieldAdded(true);

        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();
  }, [formFields, isNewFieldAdded, caseGroupDropdown]);

  serviceTypedropdown.onChange = async (selectedServiceTypeId, updatedFields) => { 
    const caseGroupField = updatedFields.find((field) => field.id === 'case_group_id');
    
    if (caseGroupField) {
      const endPoint = `${CaseGroupApi}?organisation_id=${userOrganisationId}&service_type_id=${selectedServiceTypeId}`
      await caseGroupField.fetchOptions(selectedServiceTypeId, false, endPoint);
      setFormFields([...updatedFields]);
    } 
  };

  const getAllCaseTypesData = async (page = 1) => {
    try {
      setIsLoading(true);
      await axiosPrivate.get(`${CaseTypeApi}?organisation_id=${userOrganisationId}&page=${page}&size=${perPage}`).then((resp) => {
        setAllData(resp.data.items);
        setTotalRows(resp.data.total);
      });
    } catch (error) {
      toast.error(error.response.data.detail)
      //setIsLoading(false);
    } finally {
      // Set loading to false after data is fetched (regardless of success or failure)
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const abortController = new AbortController();
    getAllCaseTypesData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
    // Navigate(detail)

  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    row.service_type_id = row.case_group.service_type_id
    serviceTypedropdown.onChange(row.service_type_id, formFields);
    setSelectedRowData(row);
  };

  const handleEditRec = async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
      case_group_id: editRecord.case_group_id,
      priority_id: editRecord.priority_id,
      sla_id: editRecord.sla_id,
      delegation_sla_id: editRecord.delegation_sla_id,
      escalation_sla_id: editRecord.escalation_sla_id,
      service_team_id: editRecord.service_team_id,
      escalation_team_id: editRecord.escalation_team_id,
      escalation_executive_team_id: editRecord.escalation_executive_team_id,
      name: editRecord.name,
      description: editRecord.description,
      is_active: editRecord.is_active,
      auto_assign: editRecord.auto_assign,
    };

    try {
      await axiosPrivate.put(`${CaseTypeApi}/${id}`, editRecordTemp).then((resp) => {
        const updatedData = allData.map((row) =>
          row.id === selectedRowData.id ? { ...row, ...resp.data } : row
        );

        setAllData(updatedData);

        toast.success(`${resp.data.name} is successfully updated.`)
        handleCloseModal()
      });
    } catch (error) {
      if (error.response && error.response.status === 422) {
        toast.error('Validation Errors');
      } else if (error.response && error.response.status === 400) {
        toast.error(error.response.data.detail);
      } else {
        toast.error('Form submission error');
      }
      throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }

    try {
      const response = await axiosPrivate.delete(`${CaseTypeApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success(DeleteSuccess);
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/crm/settings/case-type/create/${layoutURL}`;

  const tableColumns = [
    {
      name: `${CaseGroup}`,
      selector: row => `${row.case_group.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${CaseTypeName}`,
      selector: row => `${row.name}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Description}`,
      selector: row => `${row.description}`,
      sortable: true,
      center: false,
    },
    {
      name: `${Priority}`,
      selector: row => row.priority.name,
      sortable: true,
      center: false,
      cell: row => (<span style={{color: row.priority.color}}><i className="fa fa-flag"></i> {row.priority.name}</span>),
    },
    {
      name: `${ResolutionSLA}`,
      selector: row => `${row.sla.name}`,
      sortable: true,
      center: false,
    },
    /* {
      name: `${EscalationTeam}`,
      selector: row => `${row.EscalationTeam}`,
      sortable: true,
      center: false,
    },
    {
      name: `${ServiceTeam}`,
      selector: row => `${row.ServiceTeam}`,
      sortable: true,
      center: false,
    }, */
    {
      name: `${Status}`,
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  const handlePageChange = page => {
    getAllCaseTypesData(page)
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true);
    const response = await axiosPrivate.get(`${CaseTypeApi}?organisation_id=${userOrganisationId}&page=${page}&size=${perPage}`);
    setAllData(response.data.items);
    setPerPage(newPerPage);
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Breadcrumbs parent="CRM" title="Case Type " mainTitle="Case Type" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableServerFetchComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}

                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal
          isOpen={viewModalOpen}
          toggle={() => setViewModalOpen(!viewModalOpen)}
          rowData={selectedRowData}
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
              <Table>
                <tbody>

                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{CaseGroup}</th>
                    <td>{selectedRowData.case_group.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{CaseTypeName}</th>
                    <td>{selectedRowData.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Priority}</th>
                    <td><span style={{color: selectedRowData.priority.color}}><i className="fa fa-flag"></i> {selectedRowData.priority.name}</span></td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{ResolutionSLA}</th>
                    <td>{selectedRowData.sla.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{ServiceTeam}</th>
                    <td>{selectedRowData.service_team.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{EscalationTeam}</th>
                    <td>{selectedRowData.escalation_team.name}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}

        </ViewModal>

        {/* <ViewModal isOpen={viewModalOpen} toggle={() => setViewModalOpen(!viewModalOpen)} onEdit={handleEdit}>
            
          <p>ID: 456</p>
          <p>Name: Jane Smith</p>
        </ViewModal> */}
      </Container>
    </Fragment>
  );
};

export default CaseTypeList;