import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    IncomeType,
    Description,
    TypeName,
    IncomeGroup,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
    RegionalContinentApi,
    RegionalCountryApi,
    ZoneApi,
} from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

export default function useIncomeTypeController() {
    const axiosPrivate = useAxiosPrivate()
    const formTitle = IncomeType;

    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "type_name",
            name: "type_name",
            label: TypeName,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },
    ]);

    const incomeCategory = createDropdownField(axiosPrivate,
        "income_category",
        "income_category",
        IncomeGroup,
        `${RegionalContinentApi}`,
        "",
        6
    );
    incomeCategory.onChange = async (id, fields) => {
        await incomeGroup.fetchOptions();
        updateFields();
    };

    const incomeGroup = createDropdownField(axiosPrivate,
        "income_group",
        "income_group",
        IncomeType,
        `${RegionalCountryApi}`,
        "",
        6
    );
    incomeGroup.onChange = async (id, fields) => {
        await incomeClass.fetchOptions();
        updateFields();
    };

    const incomeClass = createDropdownField(axiosPrivate,
        "income_class",
        "income_class",
        IncomeType,
        `${ZoneApi}`,
        "",
        6
    );

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [
            ...formFields,
            incomeCategory,
            incomeGroup,
            incomeClass,
        ];
        await incomeCategory.fetchOptions();
        setFormFields(updatedFields);
    }

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/income-type/${layoutURL}`;

    function onSubmit(data: any) {
        //console.log(data);
    }

    return { formTitle, formFields, backBtn, onSubmit };
}
