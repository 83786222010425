import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    ExpenseType,
    Description,
    TypeName,
    ExpenseGroup,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import {
    RegionalContinentApi,
    RegionalCountryApi,
    ZoneApi,
} from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

export default function useExpenseTypeController() {
    const axiosPrivate = useAxiosPrivate()
    const formTitle = ExpenseType;

    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "type_name",
            name: "type_name",
            label: TypeName,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },
    ]);

    const expenseCategory = createDropdownField(axiosPrivate,
        "expense_category",
        "expense_category",
        ExpenseGroup,
        `${RegionalContinentApi}`,
        "",
        6
    );
    expenseCategory.onChange = async (id, fields) => {
        await expenseGroup.fetchOptions();
        updateFields();
    };

    const expenseGroup = createDropdownField(axiosPrivate,
        "expense_group",
        "expense_group",
        ExpenseType,
        `${RegionalCountryApi}`,
        "",
        6
    );
    expenseGroup.onChange = async (id, fields) => {
        await expenseClass.fetchOptions();
        updateFields();
    };

    const expenseClass = createDropdownField(axiosPrivate,
        "expense_class",
        "expense_class",
        ExpenseType,
        `${ZoneApi}`,
        "",
        6
    );

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [
            ...formFields,
            expenseCategory,
            expenseGroup,
            expenseClass,
        ];
        await expenseCategory.fetchOptions();
        setFormFields(updatedFields);
    }

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/expense-type/${layoutURL}`;

    function onSubmit(data: any) {
        //g(data);
    }

    return { formTitle, formFields, backBtn, onSubmit };
}
