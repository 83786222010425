// dashbaord
import ContactCreate from "../Components/Crm/Contact/User/Create";
import ContactList from "../Components/Crm/Contact/User/List";
import Default from "../Components/Dashboard/Default";

//Pages
//import SamplePage from "../Components/Pages/Sample";
import SamplePage from '../Components/Pages/PageLayout/SimplePage';
import ApplicationList from '../Components/System/AccessControl/Application/ApplicationList';
import ApplicationNew from '../Components/System/AccessControl/Application/ApplicationNew';
import CategoryList from "../Components/System/AccessControl/Category/CategoryList";
import CategoryNew from "../Components/System/AccessControl/Category/CategoryNew";
import GroupList from "../Components/System/AccessControl/Group/GroupList";
import GroupNew from "../Components/System/AccessControl/Group/GroupNew";
import ModuleList from "../Components/System/AccessControl/Module/ModuleList";
import ModuleNew from "../Components/System/AccessControl/Module/ModuleNew";
import TaskList from "../Components/System/AccessControl/Task/TaskList";
import TaskNew from "../Components/System/AccessControl/Task/TaskNew";
import AccessGroupTask from "../Components/System/AccessControl/AccessGroupTask/AccessGroupTask";
import AccessCategoryGroup from "../Components/System/AccessControl/AccessCategoryGroup/AccessCategoryGroup";
import ReuseComponent from "../Components/Common/TaskAssignerPage/TaskAssignerPage";
import ReusableComponentTrial from "../Components/Finance/Settings/GeneralLedgerAssign/ReusableComponentTrial";


import BankNew from "../Components/System/Settings/Basic/Bank/Create";
import BankList from "../Components/System/Settings/Basic/Bank/List";
import BankTypeNew from "../Components/System/Settings/Basic/BankType/Create";
import BankTypeList from "../Components/System/Settings/Basic/BankType/List";
import BloodGroupNew from "../Components/System/Settings/Basic/BloodGroup/Create";
import BloodGroupList from "../Components/System/Settings/Basic/BloodGroup/List";
import CurrencyNew from "../Components/System/Settings/Basic/Currency/Create";
import CurrencyList from "../Components/System/Settings/Basic/Currency/List";
import GenderNew from "../Components/System/Settings/Basic/Gender/Create";
import GenderList from "../Components/System/Settings/Basic/Gender/List";
import GenotypeNew from "../Components/System/Settings/Basic/Genotype/Create";
import GenotypeList from "../Components/System/Settings/Basic/Genotype/List";
import JobTitleNew from "../Components/System/Settings/Basic/JobTitle/Create";
import JobTitleList from "../Components/System/Settings/Basic/JobTitle/List";
import MaritalStatusNew from "../Components/System/Settings/Basic/MaritalStatus/Create";
import MaritalStatusList from "../Components/System/Settings/Basic/MaritalStatus/List";
import MedicalAilmentNew from "../Components/System/Settings/Basic/MedicalAilment/Create";
import MedicalAilmentList from "../Components/System/Settings/Basic/MedicalAilment/List";
import PfaNew from "../Components/System/Settings/Basic/Pfa/Create";
import PfaList from "../Components/System/Settings/Basic/Pfa/List";
import PfcNew from "../Components/System/Settings/Basic/Pfc/Create";
import PfcList from "../Components/System/Settings/Basic/Pfc/List";
import RelationshipNew from "../Components/System/Settings/Basic/Relationship/Create";
import RelationshipList from "../Components/System/Settings/Basic/Relationship/List";
import ReligionList from "../Components/System/Settings/Basic/Religion/List";
import ReligionNew from "../Components/System/Settings/Basic/Religion/Create";
import SalutationNew from "../Components/System/Settings/Basic/Salutation/Create";
import SalutationList from "../Components/System/Settings/Basic/Salutation/List";
import SectorNew from "../Components/System/Settings/Basic/Sector/Create";
import SectorList from "../Components/System/Settings/Basic/Sector/List";
import SubSectorNew from "../Components/System/Settings/Basic/SubSector/Create";
import SubSectorList from "../Components/System/Settings/Basic/SubSector/List";
import ContactTypeList from "../Components/System/Settings/Basic/ContactType/List";
import ContactTypeNew from "../Components/System/Settings/Basic/ContactType/Create";
import RegionalContinentList from "../Components/System/Settings/Basic/RegionalContinent/List";
import RegionalContinentNew from "../Components/System/Settings/Basic/RegionalContinent/Create";
import RegionalCountryList from "../Components/System/Settings/Basic/RegionalCountry/List";
import RegionalCountryNew from "../Components/System/Settings/Basic/RegionalCountry/Create";
import ZoneList from "../Components/System/Settings/Basic/Zone/List";
import ZoneNew from "../Components/System/Settings/Basic/Zone/Create";
import RegionalStateList from "../Components/System/Settings/Basic/RegionalState/List";
import RegionalStateNew from "../Components/System/Settings/Basic/RegionalState/Create";
import RegionalLgaList from "../Components/System/Settings/Basic/RegionalLga/List";
import RegionalLgaNew from "../Components/System/Settings/Basic/RegionalLga/Create";
import WardList from "../Components/System/Settings/Basic/Ward/List";
import WardNew from "../Components/System/Settings/Basic/Ward/Create";
import OrganisationTypeList from "../Components/System/Organisation/OrganisationType/List";
import OrganisationTypeNew from "../Components/System/Organisation/OrganisationType/Create";
import CompanyList from "../Components/System/Organisation/Company/List";
import CompanyNew from "../Components/System/Organisation/Company/Create";
import OrganisationRoutes from "./OrganisationRoutes";
import LocationTypeList from "../Components/System/Organisation/LocationType/List";
import LocationTypeNew from "../Components/System/Organisation/LocationType/Create";
import ContactRoutes from "./ContactRoutes";
import Commission from "../Components/Sales/Sales/Commision";
import PfaBankAccountList from "../Components/System/Settings/Basic/PfaBankAccount/List";
import PfaBankAccountNew from "../Components/System/Settings/Basic/PfaBankAccount/Create";
import TaxAuthorityList from "../Components/System/Settings/Basic/TaxAuthority/List";
import TaxAuthorityNew from "../Components/System/Settings/Basic/TaxAuthority/Create";

//SALES
import CreatePage from "../Components/Sales/Settings/ProductGroup/CreatePage";
import CreateType from "../Components/Sales/Settings/ProductType/CreateType";
import CreateCapacityType from "../Components/Sales/Settings/CapacityType/CreateCapacityType";
import CreateCapacity from "../Components/Sales/Settings/Capacity/CreateCapacity";
import ProductGroupList from "../Components/Sales/Settings/ProductGroup/List";
import CapacityList from "../Components/Sales/Settings/CapacityType/CapacityTypeForm/CapacityList";
import CList from "../Components/Sales/Settings/Capacity/CapacityForm/CList";
import InvoiceTypeList from "../Components/Sales/Settings/InvoiceTypes/List";
import ProductTypeList from "../Components/Sales/Settings/ProductType/ProductList";
import ProductClassList from "../Components/Sales/Settings/ProductClass/List";
import ProductClassCreate from "../Components/Sales/Settings/ProductClass/Create";
import InvoiceTypeCreate from "../Components/Sales/Settings/InvoiceTypes/Create";
import InvoiceCycleList from "../Components/Sales/Settings/InvoiceCycle/List";
import InvoiceCycleCreate from "../Components/Sales/Settings/InvoiceCycle/Create";
import CreditTermList from "../Components/Sales/Settings/CreditTerm/List";
import CreditTermCreate from "../Components/Sales/Settings/CreditTerm/Create";
import TaxList from "../Components/Sales/Settings/Tax/List";
import TaxCreate from "../Components/Sales/Settings/Tax/Create";

//ADMINISTRATION
import InventoryCategoryNew from "../Components/Administration/Settings/InventoryCategory/Create";
import InventoryCategoryList from "../Components/Administration/Settings/InventoryCategory/List";
import InventoryGroupList from "../Components/Administration/Settings/InventoryGroup/List";
import InventoryGroupNew from "../Components/Administration/Settings/InventoryGroup/Create";
import InventoryClassList from "../Components/Administration/Settings/InventoryClass/List";
import InventoryClassNew from "../Components/Administration/Settings/InventoryClass/Create";
import InventoryDescriptionList from "../Components/Administration/Settings/InventoryDescription/List";
import InventoryDescriptionNew from "../Components/Administration/Settings/InventoryDescription/Create";
import InventoryTypeList from "../Components/Administration/Settings/InventoryType/List";
import InventoryTypeNew from "../Components/Administration/Settings/InventoryType/Create";
import FixedAssetsGroupList from "../Components/Administration/Settings/FixedAssetsGroup/List";
import FixedAssetsGroupNew from "../Components/Administration/Settings/FixedAssetsGroup/Create";
import FixedAssetsClassList from "../Components/Administration/Settings/FixedAssetsClass/List";
import FixedAssetsClassNew from "../Components/Administration/Settings/FixedAssetsClass/Create";
import FixedAssetsTypeNew from "../Components/Administration/Settings/FixedAssetsType/Create";
import FixedAssetsTypeList from "../Components/Administration/Settings/FixedAssetsType/List";
import FixedAssetsDescriptionList from "../Components/Administration/Settings/FixedAssetsDescription/List";
import FixedAssetsDescriptionNew from "../Components/Administration/Settings/FixedAssetsDescription/Create";
import SpouseNew from "../Components/Administration/Settings/Spouse/Create";
import NextOfKinNew from "../Components/Administration/Settings/NextOfKin/Create";
import PaymentOnDeathNew from "../Components/Administration/Settings/PaymentOnDeath/Create"
import RefereeInformationNew from "../Components/Administration/Settings/RefreeInformation/Create";
import RefereeInformationList from "../Components/Administration/Settings/RefreeInformation/List";
import GuarantorInformationList from "../Components/Administration/Settings/GuarantorsInformation/List";
import GuarantorInformationNew from "../Components/Administration/Settings/GuarantorsInformation/Create";

//HRM
import EmployeeCategoryList from "../Components/HRM/Settings/EmployeeCategory/List";
import EmployeeCategoryCreate from "../Components/HRM/Settings/EmployeeCategory/Create";
import KycList from "../Components/HRM/Settings/Kyc/List";
import KycCreate from "../Components/HRM/Settings/Kyc/Create";
import KycAddressCreate from "../Components/HRM/Settings/Kyc address/Create";
import KycaddressList from "../Components/HRM/Settings/Kyc address/List";
import MedicalInformationCreate from "../Components/HRM/Settings/MedicalInformation/Create";
import BankPaymentInfo from "../Components/HRM/Settings/OtherInformation/Create";
import Remittance from "../Components/HRM/Settings/OtherInformation/Remittance";

// FINANCE => SETTING
import GeneralLedgerGroup from "../Components/Finance/Settings/GeneralLedgerGroup/Form/GeneralLedgerGroup";
import GeneralLedgerGroupList from "../Components/Finance/Settings/GeneralLedgerGroup/List/GeneralLedgerGroupList";
import GeneralLedgerClass from "../Components/Finance/Settings/GeneralLedgerClass/Form/GeneralLedgerClass";
import GeneralLedgerClassList from "../Components/Finance/Settings/GeneralLedgerClass/List/GeneralLedgerClassList";
import GeneralLedgerType from "../Components/Finance/Settings/GeneralLedgerType/Form/GeneralLedgerType";
import GeneralLedgerTypeList from "../Components/Finance/Settings/GeneralLedgerType/List/GeneralLedgerTypeList";
import GeneralLedgerAccount from "../Components/Finance/Settings/GeneralLedgerAccount/Form/GeneralLedgerAccount";
import GeneralLedgerAccountList from "../Components/Finance/Settings/GeneralLedgerAccount/List/GeneralLedgerAccountList";
import SubLedgerClass from "../Components/Finance/Settings/SubLedgerClass/Form/SubLedgerClass";
import SubLedgerType from "../Components/Finance/Settings/SubLedgerType/Form/SubLedgerType";
import SubLedgerAccount from "../Components/Finance/Settings/SubLedgerAccount/Form/SubLedgerAccount";
import SubLedgerAccountList from "../Components/Finance/Settings/SubLedgerAccount/List/SubLedgerAccountList";
import IncomeCategory from "../Components/Finance/Settings/IncomeCategory/Form/IncomeCategory";
import IncomeGroup from "../Components/Finance/Settings/IncomeGroup/Form/IncomeGroup";
import IncomeClass from "../Components/Finance/Settings/IncomeClass/Form/IncomeClass";
import IncomeType from "../Components/Finance/Settings/IncomeType/Form/IncomeType";
import ExpenseCategory from "../Components/Finance/Settings/ExpenseCategory/Form/ExpenseCategory";
import ExpenseGroup from "../Components/Finance/Settings/ExpenseGroup/Form/ExpenseGroup";
import ExpenseClass from "../Components/Finance/Settings/ExpenseClass/Form/ExpenseClass";
import ExpenseType from "../Components/Finance/Settings/ExpenseType/Form/ExpenseType";

import GroupGeneralLedgerClass from "../Components/Finance/Settings/GroupGeneralLedgerClass/Form/GroupGeneralLedgerClass";
import GroupGeneralLedgerClassList from "../Components/Finance/Settings/GroupGeneralLedgerClass/List/GroupGeneralLedgerClassList";
import GroupGeneralLedgerNumber from "../Components/Finance/Settings/GroupGeneralLedgerNumber/Form/GroupGeneralLedgerNumber";
import GroupGeneralLedgerNumberList from "../Components/Finance/Settings/GroupGeneralLedgerNumber/List/GroupGeneralLedgerNumberList";
import GroupGeneralLedgerType from "../Components/Finance/Settings/GroupGeneralLedgerType/Form/GroupGeneralLedgerType";
import GroupGeneralLedgerTypeList from "../Components/Finance/Settings/GroupGeneralLedgerType/List/GroupGeneralLedgerTypeList";

//CRM
import PriorityList from "../Components/Crm/ServiceManagement/Priority/List";
import PriorityCreate from "../Components/Crm/ServiceManagement/Priority/Create";
import ResolutionStatusList from "../Components/Crm/ServiceManagement/ResolutionStatus/List";
import ResolutionStatusCreate from "../Components/Crm/ServiceManagement/ResolutionStatus/Create";
import CaseCategoryCreateList from "../Components/Crm/ServiceManagement/CaseCategory/List";
import CaseCategoryCreate from "../Components/Crm/ServiceManagement/CaseCategory/Create";
import CaseList from "../Components/Crm/CaseManagement/Case/List";
import CaseCreate from "../Components/Crm/CaseManagement/Case/Create";
import TeamTypeList from "../Components/HRM/Personnel/TeamType/List";
import TeamTypeCreate from "../Components/HRM/Personnel/TeamType/Create";
import ResolutionSLACreate from "../Components/Crm/ServiceManagement/ResolutionSLA/Create";
import ResolutionSLAList from "../Components/Crm/ServiceManagement/ResolutionSLA/List";
import CaseGroupList from "../Components/Crm/ServiceManagement/CaseGroup/List";
import CaseGroupCreate from "../Components/Crm/ServiceManagement/CaseGroup/Create";
import CaseTypeList from "../Components/Crm/ServiceManagement/CaseType/List";
import CaseTypeCreate from "../Components/Crm/ServiceManagement/CaseType/Create";
import ServiceTypeList from "../Components/Crm/ServiceManagement/ServiceType/List";
import ServiceTypeCreate from "../Components/Crm/ServiceManagement/ServiceType/Create";

import ContactBranchList from "../Components/Crm/Contact/User/Branch/List";
import ContactBranchCreate from "../Components/Crm/Contact/User/Branch/Create";
import EscalationCategoryList from "../Components/Crm/ServiceManagement/EscalationCategory/List";
import EscalationCategoryCreate from "../Components/Crm/ServiceManagement/EscalationCategory/Create";

import CustomerList from "../Components/Sales/Customer/List";
import CustomersMapping from "../Components/Sales/Customer/Conversion/CustomersMapping";
import EmployeeSearch from "../Components/HRM/MapEmployee/EmployeeSearch";
import EmployeeList from "../Components/HRM/Employee/List";
import TeamList from "../Components/HRM/Personnel/Team/List";
import TeamCreate from "../Components/HRM/Personnel/Team/Create";
import TeamMember from "../Components/HRM/Personnel/TeamMember/TeamMember";
import ProductList from "../Components/Sales/Settings/Product/List";
import ProductCreate from "../Components/Sales/Settings/Product/Create";
import RootCauseList from "../Components/Crm/ServiceManagement/RootCause/List";
import RootCauseCreate from "../Components/Crm/ServiceManagement/RootCause/Create";
import ResolutionCodeList from "../Components/Crm/ServiceManagement/ResolutionCode/List";
import ResolutionCodeCreate from "../Components/Crm/ServiceManagement/ResolutionCode/Create";

import CaseDetail from "../Components/Crm/CaseManagement/Case/CaseDetail/CaseDetail";
import InteractionGroupCreate from "../Components/Crm/Settings/InteractionGroup/Create";
import InteractionGroupList from "../Components/Crm/Settings/InteractionGroup/List";
import InteractionTypeCreate from "../Components/Crm/Settings/InteractionType/Create";
import InteractionTypeList from "../Components/Crm/Settings/InteractionType/List";
import InteractionLocationList from "../Components/Crm/Settings/InteractionLocation/List";
import InteractionLocationCreate from "../Components/Crm/Settings/InteractionLocation/Create";
import InteractionPurposeList from "../Components/Crm/Settings/InteractionPurpose/List";
import InteractionPurposeCreate from "../Components/Crm/Settings/InteractionPurpose/Create";
import EscalationReasonList from "../Components/Crm/ServiceManagement/EscalationReason/List";
import EscalationReasonCreate from "../Components/Crm/ServiceManagement/EscalationReason/Create";

// Technical
import AccessNamesList from "../Components/Technical/Device/AccessNames/List";
import AccessNamesCreate from "../Components/Technical/Device/AccessNames/Create";
import DeviceInventoryList from "../Components/Technical/Device/DeviceInventory/List";
import DeviceInventoryCreate from "../Components/Technical/Device/DeviceInventory/Create";
import MakersList from "../Components/Technical/Device/Makers/List";
import MakersCreate from "../Components/Technical/Device/Makers/Create";
import ModelsList from "../Components/Technical/Device/Models/List";
import ModelsCreate from "../Components/Technical/Device/Models/Create";
import RadioTypesList from "../Components/Technical/Device/RadioTypes/List";
import RadioTypesCreate from "../Components/Technical/Device/RadioTypes/Create";
import SwitchTypesList from "../Components/Technical/Device/SwitchTypes/List";
import SwitchTypesCreate from "../Components/Technical/Device/SwitchTypes/Create";
import LinkList from "../Components/Technical/Link/Link/List";
import LinkCreate from "../Components/Technical/Link/Link/Create";
import LinkTypesList from "../Components/Technical/Link/LinkType/List";
import LinkTypesCreate from "../Components/Technical/Link/LinkType/Create";
import ImpactList from "../Components/Crm/ServiceManagement/Impact/List";
import ImpactCreate from "../Components/Crm/ServiceManagement/Impact/Create";
import UrgencyList from "../Components/Crm/ServiceManagement/Urgency/List";
import UrgencyCreate from "../Components/Crm/ServiceManagement/Urgency/Create";
import MessageGroupList from "../Components/Crm/Settings/MessageGroup/List";
import MessageGroupCreate from "../Components/Crm/Settings/MessageGroup/Create";
import MessageTypeList from "../Components/Crm/Settings/MessageType/List";
import MessageTypeCreate from "../Components/Crm/Settings/MessageType/Create";
import SalesLinkList from "../Components/Sales/Link/List";
import RouteDetailList from "../Components/System/AccessControl/RouteDetails/RouteDetailList";
import InteractionList from "../Components/Crm/InteractionManagement/Interaction/List";
import InteractionCreate from "../Components/Crm/InteractionManagement/Interaction/Create";
import InteractionDetail from "../Components/Crm/InteractionManagement/Interaction/InteractionDetail/InteractionDetail";

export const routes = [
  //{ path: `${process.env.PUBLIC_URL}/dashboard/default/:layout`, Component: () => <Default />, requiredPermission: "view-dashboard" },
  { path: `${process.env.PUBLIC_URL}/dashboard/default/:layout`, Component: () => <Default />, requiredPermission: "view:dashboard" },
  
  //page
  { path: `${process.env.PUBLIC_URL}/pages/sample-page/:layout`, Component: () => <SamplePage />, requiredPermission: "" },
  
  // Contact
  { path: `${process.env.PUBLIC_URL}/crm/contacts/:layout`, Component: () => <ContactList />, requiredPermission: "read:crm-contacts" },
  { path: `${process.env.PUBLIC_URL}/crm/contacts/create/:layout`, Component: () => <ContactCreate />, requiredPermission: "create:crm-contact" },
  { path: `${process.env.PUBLIC_URL}/crm/contacts/branches/:layout`, Component: () => <ContactBranchList />, requiredPermission: "read:crm-contact-branches" },
  { path: `${process.env.PUBLIC_URL}/crm/contacts/branches/create/:layout`, Component: () => <ContactBranchCreate />, requiredPermission: "create:crm-contact-branch" },
  { path: `/crm/contacts/*`, Component: () => <ContactRoutes />, requiredPermission: "" },

  // Application
  { path: `${process.env.PUBLIC_URL}/access-control/applications/:layout`, Component: () => <ApplicationList />, requiredPermission: "read:user-applications" },
  { path: `${process.env.PUBLIC_URL}/access-control/applications/create/:layout`, Component: () => <ApplicationNew />, requiredPermission: "create:user-application" }, 
  
  // Module
  { path: `${process.env.PUBLIC_URL}/access-control/modules/:layout`, Component: () => <ModuleList />, requiredPermission: "read:user-application-modules" },
  { path: `${process.env.PUBLIC_URL}/access-control/modules/create/:layout`, Component: () => <ModuleNew />, requiredPermission: "create:user-application-module" }, 

  // Task
  { path: `${process.env.PUBLIC_URL}/access-control/route-details/:layout`, Component: () => <RouteDetailList />, requiredPermission: "read:route-details" },
  { path: `${process.env.PUBLIC_URL}/access-control/tasks/:layout`, Component: () => <TaskList />, requiredPermission:  "read:user-application-tasks" },
  { path: `${process.env.PUBLIC_URL}/access-control/tasks/create/:layout`, Component: () => <TaskNew />, requiredPermission:  "create:user-application-task" }, 

  // Category
  { path: `${process.env.PUBLIC_URL}/access-control/categories/:layout`, Component: () => <CategoryList />, requiredPermission: "read:user-access-categories" },
  { path: `${process.env.PUBLIC_URL}/access-control/categories/create/:layout`, Component: () => <CategoryNew />, requiredPermission: "create:user-access-category" }, 

  // Group
  { path: `${process.env.PUBLIC_URL}/access-control/groups/:layout`, Component: () => <GroupList />, requiredPermission: "read:user-access-groups" },
  { path: `${process.env.PUBLIC_URL}/access-control/groups/create/:layout`, Component: () => <GroupNew />, requiredPermission: "create:user-access-group" }, 

  { path: `${process.env.PUBLIC_URL}/access-control/access-group-task/:layout`, Component: () => <AccessGroupTask />, requiredPermission: "read:user-access-group-tasks" },
  { path: `${process.env.PUBLIC_URL}/access-control/access-category-group/:layout`, Component: () => <AccessCategoryGroup />, requiredPermission: "read:user-access-category-groups" },

  // Saluations
  { path: `${process.env.PUBLIC_URL}/public-data/salutations/:layout`, Component: () => <SalutationList />, requiredPermission: "read:salutations" },
  { path: `${process.env.PUBLIC_URL}/public-data/salutations/create/:layout`, Component: () => <SalutationNew />, requiredPermission: "create:salutation" }, 

  // Gender
  { path: `${process.env.PUBLIC_URL}/public-data/genders/:layout`, Component: () => <GenderList />, requiredPermission: "read:genders" },
  { path: `${process.env.PUBLIC_URL}/public-data/genders/create/:layout`, Component: () => <GenderNew />, requiredPermission: "create:gender" }, 

  // Martial Status
  { path: `${process.env.PUBLIC_URL}/public-data/marital-statuses/:layout`, Component: () => <MaritalStatusList />, requiredPermission: "read:marital-statuses" },
  { path: `${process.env.PUBLIC_URL}/public-data/marital-statuses/create/:layout`, Component: () => <MaritalStatusNew />, requiredPermission: "create:marital-status" }, 

  // Relationship
  { path: `${process.env.PUBLIC_URL}/public-data/relationships/:layout`, Component: () => <RelationshipList />, requiredPermission: "read:relationships" },
  { path: `${process.env.PUBLIC_URL}/public-data/relationships/create/:layout`, Component: () => <RelationshipNew />, requiredPermission: "create:relationship" }, 

  // Medical Ailment
  { path: `${process.env.PUBLIC_URL}/public-data/medical-ailments/:layout`, Component: () => <MedicalAilmentList />, requiredPermission: "read:medical-ailments" },
  { path: `${process.env.PUBLIC_URL}/public-data/medical-ailments/create/:layout`, Component: () => <MedicalAilmentNew />, requiredPermission: "create:medical-ailments" }, 

  // Genotype
  { path: `${process.env.PUBLIC_URL}/public-data/genotypes/:layout`, Component: () => <GenotypeList />, requiredPermission: "read:genotypes" },
  { path: `${process.env.PUBLIC_URL}/public-data/genotypes/create/:layout`, Component: () => <GenotypeNew />, requiredPermission: "create:genotype" }, 

  // Blood Group
  { path: `${process.env.PUBLIC_URL}/public-data/blood-groups/:layout`, Component: () => <BloodGroupList />, requiredPermission: "read:blood-groups" },
  { path: `${process.env.PUBLIC_URL}/public-data/blood-groups/create/:layout`, Component: () => <BloodGroupNew />, requiredPermission: "create:blood-group" }, 
  
  // Currency
  { path: `${process.env.PUBLIC_URL}/public-data/currencies/:layout`, Component: () => <CurrencyList />, requiredPermission: "read:currencies" },
  { path: `${process.env.PUBLIC_URL}/public-data/currencies/create/:layout`, Component: () => <CurrencyNew />, requiredPermission: "create:currency" }, 

  // Religion
  { path: `${process.env.PUBLIC_URL}/public-data/religions/:layout`, Component: () => <ReligionList />, requiredPermission: "read:religions" },
  { path: `${process.env.PUBLIC_URL}/public-data/religions/create/:layout`, Component: () => <ReligionNew />, requiredPermission: "create:religion" }, 

  // Contact Type
  { path: `${process.env.PUBLIC_URL}/crm/settings/contact-types/:layout`, Component: () => <ContactTypeList />, requiredPermission: "read:contact-types" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/contact-types/create/:layout`, Component: () => <ContactTypeNew />, requiredPermission: "create:contact-type" }, 

  // Continent
  { path: `${process.env.PUBLIC_URL}/public-data/regional-continents/:layout`, Component: () => <RegionalContinentList />, requiredPermission: "read:regional-continents" },
  { path: `${process.env.PUBLIC_URL}/public-data/regional-continents/create/:layout`, Component: () => <RegionalContinentNew />, requiredPermission: "create:regional-continent" }, 

  // Country
  { path: `${process.env.PUBLIC_URL}/public-data/regional-countries/:layout`, Component: () => <RegionalCountryList />, requiredPermission: "read:regional-countries" },
  { path: `${process.env.PUBLIC_URL}/public-data/regional-countries/create/:layout`, Component: () => <RegionalCountryNew />, requiredPermission: "create:regional-country" }, 

  // Zone
  { path: `${process.env.PUBLIC_URL}/public-data/zones/:layout`, Component: () => <ZoneList />, requiredPermission: "read:regional-zones" },
  { path: `${process.env.PUBLIC_URL}/public-data/zones/create/:layout`, Component: () => <ZoneNew />, requiredPermission: "create:regional-zone" }, 
      
  // State
  { path: `${process.env.PUBLIC_URL}/public-data/regional-states/:layout`, Component: () => <RegionalStateList />, requiredPermission: "read:regional-states" },
  { path: `${process.env.PUBLIC_URL}/public-data/regional-states/create/:layout`, Component: () => <RegionalStateNew />, requiredPermission: "create:regional-state" },

  // LGA
  { path: `${process.env.PUBLIC_URL}/public-data/regional-lgas/:layout`, Component: () => <RegionalLgaList />, requiredPermission: "read:regional-lgas" },
  { path: `${process.env.PUBLIC_URL}/public-data/regional-lgas/create/:layout`, Component: () => <RegionalLgaNew />, requiredPermission: "create:regional-lga" },

  // Ward
  { path: `${process.env.PUBLIC_URL}/public-data/wards/:layout`, Component: () => <WardList />, requiredPermission: "read:regional-wards" },
  { path: `${process.env.PUBLIC_URL}/public-data/wards/create/:layout`, Component: () => <WardNew />, requiredPermission: "create:regional-ward" },

  // Sector
  { path: `${process.env.PUBLIC_URL}/public-data/sectors/:layout`, Component: () => <SectorList />, requiredPermission: "read:sectors" },
  { path: `${process.env.PUBLIC_URL}/public-data/sectors/create/:layout`, Component: () => <SectorNew />, requiredPermission: "create:sector" },

  // Sub Sector
  { path: `${process.env.PUBLIC_URL}/public-data/sub-sectors/:layout`, Component: () => <SubSectorList />, requiredPermission: "read:sub-sectors" },
  { path: `${process.env.PUBLIC_URL}/public-data/sub-sectors/create/:layout`, Component: () => <SubSectorNew />, requiredPermission: "create:sub-sector" },

  // Bank Type
  { path: `${process.env.PUBLIC_URL}/public-data/bank-types/:layout`, Component: () => <BankTypeList />, requiredPermission: "read:bank-types" },
  { path: `${process.env.PUBLIC_URL}/public-data/bank-types/create/:layout`, Component: () => <BankTypeNew />, requiredPermission: "create:bank-type" },

  // Bank
  { path: `${process.env.PUBLIC_URL}/public-data/banks/:layout`, Component: () => <BankList />, requiredPermission: "read:banks" },
  { path: `${process.env.PUBLIC_URL}/public-data/banks/create/:layout`, Component: () => <BankNew />, requiredPermission: "create:bank" },

  // PFA
  { path: `${process.env.PUBLIC_URL}/public-data/pfas/:layout`, Component: () => <PfaList />, requiredPermission: "read:pfas" },
  { path: `${process.env.PUBLIC_URL}/public-data/pfas/create/:layout`, Component: () => <PfaNew />, requiredPermission: "create:pfa" },

  // PFA Bank Accounts
  { path: `${process.env.PUBLIC_URL}/public-data/pfa-bank-accounts/:layout`, Component: () => <PfaBankAccountList />, requiredPermission: "read:pfa-bank-accounts" },
  { path: `${process.env.PUBLIC_URL}/public-data/pfa-bank-accounts/create/:layout`, Component: () => <PfaBankAccountNew />, requiredPermission: "create:pfa-bank-account" },

  // PFC
  { path: `${process.env.PUBLIC_URL}/public-data/pfcs/:layout`, Component: () => <PfcList />, requiredPermission: "read:pfcs" },
  { path: `${process.env.PUBLIC_URL}/public-data/pfcs/create/:layout`, Component: () => <PfcNew />, requiredPermission: "create:pfcs" },

  // Tax Authorities
  { path: `${process.env.PUBLIC_URL}/public-data/tax-authorities/:layout`, Component: () => <TaxAuthorityList />, requiredPermission: "read:tax-authorities" },
  { path: `${process.env.PUBLIC_URL}/public-data/tax-authorities/create/:layout`, Component: () => <TaxAuthorityNew />, requiredPermission: "create:tax-authority" },

  // Job Title
  { path: `${process.env.PUBLIC_URL}/public-data/job-titles/:layout`, Component: () => <JobTitleList />, requiredPermission: "read:job-titles" },
  { path: `${process.env.PUBLIC_URL}/public-data/job-titles/create/:layout`, Component: () => <JobTitleNew />, requiredPermission: "create:job-title" },

  // Organisation Type
  { path: `${process.env.PUBLIC_URL}/organisations/types/:layout`, Component: () => <OrganisationTypeList />, requiredPermission: "read:organisation-types" },
  { path: `${process.env.PUBLIC_URL}/organisations/types/create/:layout`, Component: () => <OrganisationTypeNew />, requiredPermission: "create:organisation-type" },

  // Organisation Location Type
  { path: `${process.env.PUBLIC_URL}/organisations/location-types/:layout`, Component: () => <LocationTypeList />, requiredPermission: "read:location-types" },
  { path: `${process.env.PUBLIC_URL}/organisations/location-types/create/:layout`, Component: () => <LocationTypeNew />, requiredPermission: "create:location-type" },

  // Company
  { path: `${process.env.PUBLIC_URL}/organisations/companies/:layout`, Component:() =>  <CompanyList/> , requiredPermission: "read:companies" },
  { path: `${process.env.PUBLIC_URL}/organisations/companies/create/:layout`, Component: () => <CompanyNew />, requiredPermission: "create:company" },
 // { path: `/organisations/companies/:id/structures/*`, Component: () => <OrganisationRoutes />, requiredPermission: false },

  // FINANCE 
  // -- SETTINGS
  { path: `${process.env.PUBLIC_URL}/finance/settings/reuse/:layout`, Component: () => <ReuseComponent />, requiredPermission: "" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/general-ledger-assign/:layout`, Component: () => <ReusableComponentTrial />, requiredPermission: "" },
  
  { path: `${process.env.PUBLIC_URL}/finance/settings/general-ledger-group/:layout`, Component: () => <GeneralLedgerGroupList />, requiredPermission: "read:finance-gl-groups" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/general-ledger-group/create/:layout`, Component: () => <GeneralLedgerGroup />, requiredPermission: "create:finance-gl-group" },

  { path: `${process.env.PUBLIC_URL}/finance/settings/general-ledger-class/:layout`, Component: () => <GeneralLedgerClassList />, requiredPermission: "read:finance-gl-classes" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/general-ledger-class/create/:layout`, Component: () => <GeneralLedgerClass />, requiredPermission: "create:finance-gl-class" },

  { path: `${process.env.PUBLIC_URL}/finance/settings/general-ledger-type/:layout`, Component: () => <GeneralLedgerTypeList />, requiredPermission: "read:finance-gl-types" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/general-ledger-type/create/:layout`, Component: () => <GeneralLedgerType />, requiredPermission: "create:finance-gl-type" },

  { path: `${process.env.PUBLIC_URL}/finance/settings/general-ledger-account/:layout`, Component: () => <GeneralLedgerAccountList />, requiredPermission: "read:finance-gl-accounts" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/general-ledger-account/create/:layout`, Component: () => <GeneralLedgerAccount />, requiredPermission: "create:finance-gl-account" },
  // ---------------- //TODO: ------------------------------------- //
  { path: `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-class/:layout`, Component: () => <SubLedgerClass />, requiredPermission: "create:finance-sl-class" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-type/:layout`, Component: () => <SubLedgerType />, requiredPermission: "create:finance-sl-type" },

  { path: `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-account/:layout`, Component: () => <SubLedgerAccountList />, requiredPermission: "read:finance-sl-accounts" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/sub-ledger-account/create/:layout`, Component: () => <SubLedgerAccount />, requiredPermission: "create:finance-sl-account" },

  { path: `${process.env.PUBLIC_URL}/finance/settings/group-general-ledger-class/:layout`, Component: () => <GroupGeneralLedgerClassList />, requiredPermission: "read:finance-group-gl-classes" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/group-general-ledger-class/create/:layout`, Component: () => <GroupGeneralLedgerClass />, requiredPermission: "create:finance-group-gl-class" },

  { path: `${process.env.PUBLIC_URL}/finance/settings/group-general-ledger-number/:layout`, Component: () => <GroupGeneralLedgerNumberList />, requiredPermission: "read:finance-group-gl-numbers" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/group-general-ledger-number/create/:layout`, Component: () => <GroupGeneralLedgerNumber />, requiredPermission: "create:finance-group-gl-number" },

  { path: `${process.env.PUBLIC_URL}/finance/settings/group-general-ledger-type/:layout`, Component: () => <GroupGeneralLedgerTypeList />, requiredPermission: "read:finance-group-gl-types" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/group-general-ledger-type/create/:layout`, Component: () => <GroupGeneralLedgerType />, requiredPermission: "create:finance-group-gl-type" },

  { path: `${process.env.PUBLIC_URL}/finance/settings/income-category/:layout`, Component: () => <IncomeCategory />, requiredPermission: "read:finance-income-categories" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/income-group/:layout`, Component: () => <IncomeGroup />, requiredPermission: "create:finance-income-category" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/income-class/:layout`, Component: () => <IncomeClass />, requiredPermission: "read:finance-income-classes" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/income-type/:layout`, Component: () => <IncomeType />, requiredPermission: "create:finance-income-class" },

  { path: `${process.env.PUBLIC_URL}/finance/settings/expense-category/:layout`, Component: () => <ExpenseCategory />, requiredPermission: "read:finance-expense-categories" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/expense-group/:layout`, Component: () => <ExpenseGroup />, requiredPermission: "read:finance-expense-groups" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/expense-class/:layout`, Component: () => <ExpenseClass />, requiredPermission: "read:finance-expense-classes" },
  { path: `${process.env.PUBLIC_URL}/finance/settings/expense-type/:layout`, Component: () => <ExpenseType />, requiredPermission: "read:finance-expense-types" },

  // SALES
  { path: `${process.env.PUBLIC_URL}/sales/commission/:layout`, Component: () => <Commission />, requiredPermission: "" },
  { path: `${process.env.PUBLIC_URL}/sales/customers/:layout`, Component: () => <CustomerList />, requiredPermission: "read:sale-customers" },
  { path: `${process.env.PUBLIC_URL}/sales/customers/mapping/:layout`, Component: () => <CustomersMapping />, requiredPermission: "read:sale-customer-mapping" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/product-groups/:layout`, Component: () => <ProductGroupList />, requiredPermission: "read:sale-product-groups" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/product-groups/create/:layout`, Component: () => <CreatePage />, requiredPermission: "create:sale-product-group" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/product-types/:layout`, Component: () => <ProductTypeList />, requiredPermission: "read:sale-product-types" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/product-types/create/:layout`, Component: () => <CreateType />, requiredPermission: "create:sale-product-type" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/products/:layout`, Component: () => <ProductList />, requiredPermission: "read:sale-products" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/products/create/:layout`, Component: () => <ProductCreate />, requiredPermission: "create:sale-product" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/capacity-types/:layout`, Component: () => <CapacityList />, requiredPermission: "read:sale-capacity-types" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/capacity-types/create/:layout`, Component: () => <CreateCapacityType />, requiredPermission: "create:sale-capacity-type" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/capacities/:layout`, Component: () => <CList />, requiredPermission: "read:sale-capacities" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/capacities/create/:layout`, Component: () => <CreateCapacity />, requiredPermission: "create:sale-capacity" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/product-classes/:layout`, Component: () => <ProductClassList />, requiredPermission: "read:sale-product-classes" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/product-classes/create/:layout`, Component: () => <ProductClassCreate />, requiredPermission: "create:sale-product-classes" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/invoice-types/:layout`, Component: () => <InvoiceTypeList />, requiredPermission: "read:sale-invoice-types" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/invoice-types/create/:layout`, Component: () => <InvoiceTypeCreate />, requiredPermission: "create:sale-invoice-type" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/invoice-cycles/:layout`, Component: () => <InvoiceCycleList />, requiredPermission: "read:sale-invoice-cycles" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/invoice-cycles/create/:layout`, Component: () => <InvoiceCycleCreate />, requiredPermission: "create:sale-invoice-cycle" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/credit-terms/:layout`, Component: () => <CreditTermList />, requiredPermission: "read:sale-credit-terms" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/credit-terms/create/:layout`, Component: () => <CreditTermCreate />, requiredPermission: "create:sale-credit-term" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/taxes/:layout`, Component: () => <TaxList />, requiredPermission: "read:sale-taxes" },
  { path: `${process.env.PUBLIC_URL}/sales/settings/taxes/create/:layout`, Component: () => <TaxCreate />, requiredPermission: "create:sale-tax" },

  //------ADMINISTRATION
  { path: `${process.env.PUBLIC_URL}/administration/settings/inventory-category/:layout`, Component: () => <InventoryCategoryList />, requiredPermission: "read:administration-inventory-categories" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/inventory-category/create/:layout`, Component: () => <InventoryCategoryNew />, requiredPermission: "create:administration-inventory-category" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/inventory-group/:layout`, Component: () => <InventoryGroupList />, requiredPermission: "read:administration-inventory-groups" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/inventory-group/create/:layout`, Component: () => <InventoryGroupNew />, requiredPermission: "create:administration-inventory-group" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/inventory-class/:layout`, Component: () => <InventoryClassList />, requiredPermission: "read:administration-inventory-classes" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/inventory-class/create/:layout`, Component: () => <InventoryClassNew />, requiredPermission: "create:administration-inventory-class" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/inventory-description/:layout`, Component: () => <InventoryDescriptionList />, requiredPermission: "read:administration-inventory-descriptions" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/inventory-description/create/:layout`, Component: () => <InventoryDescriptionNew />, requiredPermission: "create:administration-inventory-description" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/inventory-type/:layout`, Component: () => <InventoryTypeList />, requiredPermission: "read:administration-inventory-types" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/inventory-type/create/:layout`, Component: () => <InventoryTypeNew />, requiredPermission: "create:administration-inventory-type" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/fixed-assets-group/:layout`, Component: () => <FixedAssetsGroupList />, requiredPermission: "read:administration-fixed-assets-groups" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/fixed-assets-group/create/:layout`, Component: () => <FixedAssetsGroupNew />, requiredPermission: "create:administration-fixed-assets-group" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/fixed-assets-class/:layout`, Component: () => <FixedAssetsClassList />, requiredPermission: "read:administration-fixed-assets-classes" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/fixed-assets-class/create/:layout`, Component: () => <FixedAssetsClassNew />, requiredPermission: "create:administration-fixed-assets-class" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/fixed-assets-type/:layout`, Component: () => <FixedAssetsTypeList />, requiredPermission: "read:administration-fixed-assets-types" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/fixed-assets-type/create/:layout`, Component: () => <FixedAssetsTypeNew />, requiredPermission: "create:administration-fixed-assets-type" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/fixed-assets-description/:layout`, Component: () => <FixedAssetsDescriptionList />, requiredPermission: "read:administration-fixed-assets-descriptions" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/fixed-assets-description/create/:layout`, Component: () => <FixedAssetsDescriptionNew />, requiredPermission: "create:administration-fixed-assets-description" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/spouse/create/:layout`, Component: () => <SpouseNew />, requiredPermission: "" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/next-of-kin/create/:layout`, Component: () => <NextOfKinNew />, requiredPermission: "" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/payment-on-death/create/:layout`, Component: () => <PaymentOnDeathNew />, requiredPermission: "" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/referee-information/:layout`, Component: () => <RefereeInformationList />, requiredPermission: "" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/referee-information/create/:layout`, Component: () => <RefereeInformationNew />, requiredPermission: "" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/guarantor-information/:layout`, Component: () => <GuarantorInformationList />, requiredPermission: "" },
  { path: `${process.env.PUBLIC_URL}/administration/settings/guarantor-information/create/:layout`, Component: () => <GuarantorInformationNew />, requiredPermission: "" },

  //------ HRM
  //Employee 
  { path: `${process.env.PUBLIC_URL}/hrm/employees/contact-mapping/:layout`, Component: () => <EmployeeSearch />, requiredPermission: "read:hrm-employee-mapping" },
  { path: `${process.env.PUBLIC_URL}/hrm/employees/:layout`, Component: () => <EmployeeList />, requiredPermission: "read:hrm-employees" },

  { path: `${process.env.PUBLIC_URL}/hrm/settings/employee-categories/:layout`, Component: () => <EmployeeCategoryList />, requiredPermission: "read:hrm-employee-categories" },
  { path: `${process.env.PUBLIC_URL}/hrm/settings/employee-categories/create/:layout`, Component: () => <EmployeeCategoryCreate />, requiredPermission: "create:hrm-employee-category" },
  { path: `${process.env.PUBLIC_URL}/hrm/settings/kyc/:layout`, Component: () => <KycList />, requiredPermission: "" },
  { path: `${process.env.PUBLIC_URL}/hrm/settings/kyc/create/:layout`, Component: () => <KycCreate />, requiredPermission: "" },
  { path: `${process.env.PUBLIC_URL}/hrm/settings/kyc-address-proof/:layout`, Component: () => <KycaddressList />, requiredPermission: "" },
  { path: `${process.env.PUBLIC_URL}/hrm/settings/kyc-address-proof/create/:layout`, Component: () => <KycAddressCreate />, requiredPermission: "" },
  { path: `${process.env.PUBLIC_URL}/hrm/settings/medical-information/create/:layout`, Component: () => <MedicalInformationCreate />, requiredPermission: "" },
  { path: `${process.env.PUBLIC_URL}/hrm/settings/bank-payment-info-create/:layout`, Component: () => <BankPaymentInfo />, requiredPermission: "" },
  { path: `${process.env.PUBLIC_URL}/hrm/settings/remittance-codes/create/:layout`, Component: () => <Remittance />, requiredPermission: "" },
  { path: `${process.env.PUBLIC_URL}/hrm/personnel/team-types/:layout`, Component: () => <TeamTypeList />, requiredPermission: "read:hrm-team-types" },
  { path: `${process.env.PUBLIC_URL}/hrm/personnel/team-types/create/:layout`, Component: () => <TeamTypeCreate />, requiredPermission: "create:hrm-team-type" },
  { path: `${process.env.PUBLIC_URL}/hrm/personnel/teams/:layout`, Component: () => <TeamList />, requiredPermission: "read:hrm-teams" },
  { path: `${process.env.PUBLIC_URL}/hrm/personnel/teams/create/:layout`, Component: () => <TeamCreate />, requiredPermission: "create:hrm-team" },
  { path: `${process.env.PUBLIC_URL}/hrm/personnel/team-members/:layout`, Component: () => <TeamMember />, requiredPermission: "read:hrm-team-members-by-team" },

  // CRM -> SETTINGS
  { path: `${process.env.PUBLIC_URL}/crm/settings/impacts/:layout`, Component: () => <ImpactList />, requiredPermission: "read:crm-impacts" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/impacts/create/:layout`, Component: () => <ImpactCreate />, requiredPermission: "create:crm-impact" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/urgencies/:layout`, Component: () => <UrgencyList />, requiredPermission: "read:crm-urgencies" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/urgencies/create/:layout`, Component: () => <UrgencyCreate />, requiredPermission: "create:crm-urgency" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/priority/:layout`, Component: () => <PriorityList />, requiredPermission: "read:crm-priorities" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/priority/create/:layout`, Component: () => <PriorityCreate />, requiredPermission: "create:crm-priority" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/resolution-status/:layout`, Component: () => <ResolutionStatusList />, requiredPermission: "read:crm-resolution-statuses" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/resolution-status/create/:layout`, Component: () => <ResolutionStatusCreate />, requiredPermission: "create:crm-resolution-status" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/resolution-codes/:layout`, Component: () => <ResolutionCodeList />, requiredPermission: "read:crm-resolution-codes" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/resolution-codes/create/:layout`, Component: () => <ResolutionCodeCreate />, requiredPermission: "create:crm-resolution-code" },
  { path: `${process.env.PUBLIC_URL}/crm/case-management/case/:layout`, Component: () => <CaseList />, requiredPermission: "read:crm-cases" },
  { path: `${process.env.PUBLIC_URL}/crm/case-management/case/create/:layout`, Component: () => <CaseCreate />, requiredPermission: "create:crm-case" },
  { path: `${process.env.PUBLIC_URL}/crm/case-management/case/:track_number/:layout`, Component: () => <CaseDetail />, requiredPermission: "read:crm-case-by-track-number" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/case-category/:layout`, Component: () => <CaseCategoryCreateList />, requiredPermission: "read:crm-case-categories" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/case-category/create/:layout`, Component: () => <CaseCategoryCreate />, requiredPermission: "create:crm-case-category" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/sla/:layout`, Component: () => <ResolutionSLAList />, requiredPermission: "read:crm-slas" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/sla/create/:layout`, Component: () => <ResolutionSLACreate />, requiredPermission: "create:crm-sla" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/case-group/:layout`, Component: () => <CaseGroupList />, requiredPermission: "read:crm-case-groups" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/case-group/create/:layout`, Component: () => <CaseGroupCreate />, requiredPermission: "create:crm-case-group" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/case-type/:layout`, Component: () => <CaseTypeList />, requiredPermission: "read:crm-case-types" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/case-type/create/:layout`, Component: () => <CaseTypeCreate />, requiredPermission: "create:crm-case-type" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/root-causes/:layout`, Component: () => <RootCauseList />, requiredPermission: "read:crm-root-causes" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/root-causes/create/:layout`, Component: () => <RootCauseCreate />, requiredPermission: "create:crm-root-cause" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/service-type/:layout`, Component: () => <ServiceTypeList />, requiredPermission: "read:crm-service-types" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/service-type/create/:layout`, Component: () => <ServiceTypeCreate />, requiredPermission: "create:crm-service-type" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/escalation-categories/:layout`, Component: () => <EscalationCategoryList />, requiredPermission: "read:crm-escalation-categories" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/escalation-categories/create/:layout`, Component: () => <EscalationCategoryCreate />, requiredPermission: "create:crm-escalation-category" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/escalation-reasons/:layout`, Component: () => <EscalationReasonList />, requiredPermission: "read:crm-escalation-reasons" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/escalation-reasons/create/:layout`, Component: () => <EscalationReasonCreate />, requiredPermission: "create:crm-escalation-reason" },

  { path: `${process.env.PUBLIC_URL}/crm/settings/interaction-groups/:layout`, Component: () => <InteractionGroupList />, requiredPermission: "read:crm-interaction-groups" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/interaction-groups/create/:layout`, Component: () => <InteractionGroupCreate />, requiredPermission: "create:crm-interaction-group" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/interaction-types/:layout`, Component: () => <InteractionTypeList />, requiredPermission: "read:crm-interaction-types" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/interaction-types/create/:layout`, Component: () => <InteractionTypeCreate />, requiredPermission: "create:crm-interaction-type" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/interaction-locations/:layout`, Component: () => <InteractionLocationList />, requiredPermission: "read:crm-interaction-locations" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/interaction-locations/create/:layout`, Component: () => <InteractionLocationCreate />, requiredPermission: "create:crm-interaction-location" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/interaction-purposes/:layout`, Component: () => <InteractionPurposeList />, requiredPermission: "read:crm-interaction-purposes" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/interaction-purposes/create/:layout`, Component: () => <InteractionPurposeCreate />, requiredPermission: "create:crm-interaction-purpose" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/message-group/:layout`, Component: () => <MessageGroupList />, requiredPermission: "read:crm-message-groups" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/message-group/create/:layout`, Component: () => <MessageGroupCreate />, requiredPermission: "create:crm-message-group" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/message-type/:layout`, Component: () => <MessageTypeList />, requiredPermission: "read:crm-message-types" },
  { path: `${process.env.PUBLIC_URL}/crm/settings/message-type/create/:layout`, Component: () => <MessageTypeCreate />, requiredPermission: "create:crm-message-type" },

  { path: `${process.env.PUBLIC_URL}/crm/interaction-management/interactions/:layout`, Component: () => <InteractionList />, requiredPermission: "read:crm-interactions" },
  { path: `${process.env.PUBLIC_URL}/crm/interaction-management/interactions/create/:layout`, Component: () => <InteractionCreate />, requiredPermission: "create:crm-interactions" },
  { path: `${process.env.PUBLIC_URL}/crm/interaction-management/interactions/:track_number/:layout`, Component: () => <InteractionDetail />, requiredPermission: "read:crm-interaction-by-track-number" },

  { path: `${process.env.PUBLIC_URL}/technical/access-names/:layout`, Component: () => <AccessNamesList />, requiredPermission: "read:technical-access-names" },
  { path: `${process.env.PUBLIC_URL}/technical/access-names/create/:layout`, Component: () => <AccessNamesCreate />, requiredPermission: "create:technical-access-name" },

  { path: `${process.env.PUBLIC_URL}/technical/device-inventory/:layout`, Component: () => <DeviceInventoryList />, requiredPermission: "read:technical-device-inventories" },
  { path: `${process.env.PUBLIC_URL}/technical/device-inventory/create/:layout`, Component: () => <DeviceInventoryCreate />, requiredPermission: "create:technical-device-inventory" },

  { path: `${process.env.PUBLIC_URL}/technical/makers/:layout`, Component: () => <MakersList />, requiredPermission: "read:technical-device-makers" },
  { path: `${process.env.PUBLIC_URL}/technical/makers/create/:layout`, Component: () => <MakersCreate />, requiredPermission: "create:technical-device-maker" },

  { path: `${process.env.PUBLIC_URL}/technical/models/:layout`, Component: () => <ModelsList />, requiredPermission: "read:technical-device-models" },
  { path: `${process.env.PUBLIC_URL}/technical/models/create/:layout`, Component: () => <ModelsCreate />, requiredPermission: "create:technical-device-model" },

  { path: `${process.env.PUBLIC_URL}/technical/radio-types/:layout`, Component: () => <RadioTypesList />, requiredPermission: "read:technical-device-radio-types" },
  { path: `${process.env.PUBLIC_URL}/technical/radio-types/create/:layout`, Component: () => <RadioTypesCreate />, requiredPermission: "create:technical-device-radio-type" },

  { path: `${process.env.PUBLIC_URL}/technical/switch-types/:layout`, Component: () => <SwitchTypesList />, requiredPermission: "read:technical-device-switch-types" },
  { path: `${process.env.PUBLIC_URL}/technical/switch-types/create/:layout`, Component: () => <SwitchTypesCreate />, requiredPermission: "create:technical-device-switch-type" },

  { path: `${process.env.PUBLIC_URL}/technical/link/:layout`, Component: () => <LinkList />, requiredPermission: "read:technical-links" },
  { path: `${process.env.PUBLIC_URL}/technical/link/create/:layout`, Component: () => <LinkCreate />, requiredPermission: "create:technical-link" },

  { path: `${process.env.PUBLIC_URL}/technical/link-types/:layout`, Component: () => <LinkTypesList />, requiredPermission: "read:technical-link-types" },
  { path: `${process.env.PUBLIC_URL}/technical/link-types/create/:layout`, Component: () => <LinkTypesCreate />, requiredPermission: "create:technical-link-type" },

  { path: `${process.env.PUBLIC_URL}/sales/links/:layout`, Component: () => <SalesLinkList />, requiredPermission: "read:sale-customer-links" },
];
