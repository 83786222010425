import { useState } from "react";
import axios from "../../../../../api/axios";

export const subLedgerTypeForm = [
    {
        id: "name",
        name: "name",
        label: `Name`,
        type: "text",
        value: "",
        required: true,
        colSize: 6,
    },

    {
        id: "description",
        name: "description",
        label: `Description`,
        type: "textarea",
        value: "",
        required: true,
        colSize: 6,
    },

    {
        id: "general_ledger_group",
        name: "general_ledger_group",
        label: `General Ledger Group`,
        type: "select",
        required: true,
        colSize: 6,
        options: [
            { label: "Sales Ledger", value: "sales-ledger" },
            { label: "Purchase Ledger", value: "purchase-ledger" },
            { label: "General Ledger", value: "general-ledger" },
        ],
        async fetchOptions() {
            try {
                const response = await axios.get("/");
                this.options = response.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
            } catch (error) {
                console.error("Error fetching sector options:", error);
            }
        },
        onChange: (value: any) => {}, // handle the change event here
    },

    {
        id: "general_ledger_class",
        name: "general_ledger_class",
        label: `General Ledger Class`,
        type: "select",
        required: true,
        colSize: 6,
        options: [
            { label: "Sales Ledger Class", value: "sales-ledger-class" },
            { label: "Purchase Ledger Class", value: "purchase-ledger-class" },
            { label: "General Ledger Class", value: "general-ledger-class" },
        ],
        async fetchOptions() {
            try {
                const response = await axios.get("/");
                this.options = response.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
            } catch (error) {
                console.error("Error fetching sector options:", error);
            }
        },
        onChange: (value: any) => {}, // handle the change event here
    },

    {
        id: "general_ledger_type",
        name: "general_ledger_type",
        label: `General Ledger Type`,
        type: "select",
        required: true,
        colSize: 6,
        options: [
            { label: "Sales Ledger Type", value: "sales-ledger-type" },
            { label: "Purchase Ledger Type", value: "purchase-ledger-type" },
            { label: "General Ledger Type", value: "general-ledger-type" },
        ],
        async fetchOptions() {
            try {
                const response = await axios.get("/");
                this.options = response.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
            } catch (error) {
                console.error("Error fetching sector options:", error);
            }
        },
        onChange: (value: any) => {}, // handle the change event here
    },

    {
        id: "general_ledger_account",
        name: "general_ledger_account",
        label: `General Ledger Acc.`,
        type: "select",
        required: true,
        colSize: 6,
        options: [
            {
                label: "Sales Group Ledger Type",
                value: "sales-group-ledger-type",
            },
            {
                label: "Purchase Group Ledger Type",
                value: "purchase-ledger-type",
            },
            {
                label: "General Group Ledger Type",
                value: "general-ledger-type",
            },
        ],
        async fetchOptions() {
            try {
                const response = await axios.get("/");
                this.options = response.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
            } catch (error) {
                console.error("Error fetching sector options:", error);
            }
        },
        onChange: (value: any) => {}, // handle the change event here
    },

    {
        id: "sub_ledger_class",
        name: "sub_ledger_class",
        label: `Sub Ledger Class`,
        type: "select",
        required: true,
        colSize: 6,
        options: [
            {
                label: "Sales Sub Ledger Type",
                value: "sales-group-ledger-type",
            },
            {
                label: "Purchase Sub Ledger Type",
                value: "purchase-ledger-type",
            },
            {
                label: "General Sub Ledger Type",
                value: "general-ledger-type",
            },
        ],
        async fetchOptions() {
            try {
                const response = await axios.get("/");
                this.options = response.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
            } catch (error) {
                console.error("Error fetching sector options:", error);
            }
        },
        onChange: (value: any) => {}, // handle the change event here
    },

    {
        id: "status",
        name: "status",
        label: `Status`,
        type: "switch",
        value: "1",
        colSize: 6,
    },
];

export default function useSubLedgerTypeController() {
    const formTitle = "Sub Ledger Type";
    const [formFields] = useState(subLedgerTypeForm);
    function onSubmit(data: any) {
       
    }

    return { formTitle, formFields, onSubmit };
}
