import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Btn, H4, P, Image, Alerts } from '../AbstractElements';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import logoWhite from '../assets/images/logo/logo.png';
import logoDark from '../assets/images/logo/logo_dark.png';

import { EmailAddress, ForgotPassword, Password, RememberPassword, SignIn } from '../Constant';

//import { Jwt_token } from '../Config/Config';
import man from '../assets/images/dashboard/profile.png';
//import { handleResponse } from '../Services/fack.backend';

import useAuth from '../Hooks/useAuth';
import axios from '../api/axios';
//import useLocalStorage from '../Hooks/useLocalStorage';
import useToggle from '../Hooks/useToggle';
import Spinner from '../Components/Common/Component/Spinner';
const LOGIN_URL = '/auth/login';
//import { useCookies } from "react-cookie";

const LoginForm = ({ logoClassMain }) => {

  const { setAuth } = useAuth();
  //const [cookies, setCookie] = useCookies(["user"]);
  
  const [check, toggleCheck] = useToggle('persist', true);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const userRef = useRef();
  const errRef = useRef();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [togglePassword, setTogglePassword] = useState(false);
  //const history = useNavigate();
  //const { layoutURL } = useContext(CustomizerContext);
  const [errMsg, setErrMsg] = useState('');

  const [value
    //, setValue
    ] = useState(localStorage.getItem('profileURL' || man));
  const [name
    //, setName
    ] = useState(localStorage.getItem('Name'));

  useEffect(() => {
    localStorage.setItem('profileURL', man);
    localStorage.setItem('Name', 'Guest');
  }, [value, name]);

  useEffect(() => {
      userRef.current.focus();
  }, [])

  useEffect(() => {
      setErrMsg('');
  }, [email, password])

  /* const loginAuth = async (e) => {
    e.preventDefault();
    setValue(man);
    setName('Guest');
    if (email !== '' && password !== '') {
      localStorage.setItem('login', JSON.stringify(true));
      history(`${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`);
    }
  }; */

/*   const loginWithJwt = (e) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: { email, password },
    };

    return fetch('/users/authenticate', requestOptions)
      .then(handleResponse)
      .then((user) => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        setValue(man);
        setName('Emay Walter');
        localStorage.setItem('token', Jwt_token);
        //window.location.href = `${process.env.PUBLIC_URL}/pages/sample-page/${layoutURL}`;
        window.location.href = `${process.env.PUBLIC_URL}/dashboard/default/${layoutURL}`;
        return user;
      });
  }; */
 

 const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
        setLoading(true); 
        const response = await axios.post(LOGIN_URL,
            JSON.stringify(`grant_type=&username=${email}&password=${password}&scope=&client_id=&client_secret=`),
          {
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              withCredentials: true
          } 
        );

        const accessToken = response?.data?.access_token;
        const refreshToken = response?.data?.refresh_token;
        const expires_in = response?.data?.expires_in;
        const roles = response?.data?.roles;
        const user = response?.data?.user;
        setAuth({ user, email, roles, accessToken, refreshToken, expires_in }); 
        
        const name = `${user.contact.last_name} ${user.contact.first_name}`
        localStorage.setItem('Name', name);
        localStorage.setItem('auth0_profile', JSON.stringify(user));
        localStorage.setItem('authenticated', true);
       
        setPassword('');
        navigate(from, { replace: true });
    } catch (err) {
      
        if (!err?.response) {
            setErrMsg('No Server Response');
        } else if (err.response?.status === 422) {
            setErrMsg('Missing Username or Password');
        } else if (err.response?.status === 401) {
            setErrMsg('Unauthorized');
        } else if (err.response && err.response.status === 400) {
            setErrMsg(err.response.data.detail);
        } else {
            setErrMsg('Login Failed');
        }
       // errRef.current.focus();
    } 
    finally {
      setLoading(false);
    }
  }

/*   const togglePersist = () => {
    setPersist(prev => !prev);
  }

  useEffect(() => {
      localStorage.setItem("persist", persist);
  }, [persist]) */

  return (
    <Fragment>
      <div className='login-card'>
        <div>
          <div>
            <Link className={`logo ${logoClassMain ? logoClassMain : ''}`} to={process.env.PUBLIC_URL}>
              <Image attrImage={{ className: 'img-fluid for-light', src: logoWhite, alt: 'looginpage' }} />
              <Image attrImage={{ className: 'img-fluid for-dark', src: logoDark, alt: 'looginpage' }} />
            </Link>
          </div>

          <div className='login-main'>
            {errMsg && <Alerts attrAlert={{ color: 'danger ' }} >
              <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
              </Alerts>}
          
            <Form className='theme-form login-form' onSubmit={handleSubmit}>
              <H4>Sign in to account</H4>
              <P>Enter your email & password to login</P>
              <FormGroup>
                <Label className='col-form-label m-0' htmlFor="email">{EmailAddress}</Label>
                <Input className='form-control' type='email'  id='email'
                onChange={(e) => setEmail(e.target.value)} 
                value={email}
                ref={userRef}
                autoComplete="off" required/>
                
              </FormGroup>

              <FormGroup className='position-relative'>
                <Label className='col-form-label m-0' htmlFor="password">{Password}</Label>
                <div className='position-relative'>
                  <Input className='form-control' type={togglePassword ? 'text' : 'password'}
                    id='password'
                   onChange={(e) => setPassword(e.target.value)} value={password} />
                  <div className='show-hide' onClick={() => setTogglePassword(!togglePassword)}>
                    <span className={togglePassword ? '' : 'show'}></span>
                  </div>
                </div>
              </FormGroup>

              <FormGroup className='position-relative'>
                <div className='checkbox'>
                  <Input id='checkbox1' type='checkbox' />
                  <Label className='text-muted' for='checkbox1'>
                  {RememberPassword}
                  </Label>
                </div>
                <Link className='link' to="#javascript">
                  {ForgotPassword}
                </Link>
              </FormGroup>
              <FormGroup>
                <Btn attrBtn={{ color: 'primary', className: 'd-block w-100 mt-2' }}>
                  {loading ? <Spinner/> :  `${SignIn}`}
                </Btn>
              </FormGroup>
              <div className="persistCheck">
                    <input
                        type="hidden"
                        id="persist"
                        onChange={toggleCheck}
                        checked={check}
                    />
                    {/* <label htmlFor="persist">Trust This Device</label> */}
                </div>
            </Form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default LoginForm;
