import React, { Fragment } from "react";
import { Card, CardBody, Col, Container, Row, Table } from "reactstrap";
import useGroupGeneralLedgerNumberListController from "./GroupGeneralLedgerNumberList.controller";
import data from "./group-general-ledger-number-data.json";
import { Breadcrumbs } from "../../../../../AbstractElements";
import HeaderCard from "../../../../Common/Component/HeaderCard";
import DataTableComponent from "../../../../Common/Component/DataTableComponent";
import ViewModal from "../../../../Common/Component/ViewModal";
import { groupGeneralLedgerNumberForm } from "../Form/GroupGeneralLedgerNumber.controller";
// import { formatDateWithTimeZone } from "../../../../../_helper/dateHelper";

const GroupGeneralLedgerNumber = () => {
    const {
        data,
        tableColumns,
        newBtn,
        editMode,
        selectedRowData,
        setViewModalOpen,
        viewModalOpen,
        handleView,
        handleEdit,
        memoizedHandleEdit,
    } = useGroupGeneralLedgerNumberListController();

    return (
        <Fragment>
            <Breadcrumbs
                parent="Settings"
                title="Group G-L Number"
                mainTitle="Group G-L Number"
            />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">
                        <Card>
                            <HeaderCard
                                title="Group G-L Number List"
                                btnUrl={newBtn}
                                span1={undefined}
                                span2={undefined}
                                mainClasses={undefined}
                            />
                            <CardBody>
                                <DataTableComponent
                                    tabledata={data}
                                    tableColumns={tableColumns}
                                    isLoading={false}
                                    onView={handleView}
                                    // onDelete={handleDelete}
                                    onEdit={handleEdit}
                                    useTableAction={undefined}
                                    addToDefaultAction={undefined}
                                    onDelete={undefined}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <ViewModal
                    isOpen={viewModalOpen}
                    toggle={() => setViewModalOpen(!viewModalOpen)}
                    rowData={selectedRowData}
                    formFields={groupGeneralLedgerNumberForm}
                    editMode={editMode}
                    onEdit={memoizedHandleEdit}
                    onSubmit={undefined}
                    modalTitle={undefined}
                >
                    {selectedRowData && (
                        <>
                            <Table>
                                <tbody>
                                    <tr className="border-bottom-primary">
                                        <th scope="row">Name</th>
                                        <td>{selectedRowData.name}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">Description</th>
                                        <td>{selectedRowData.description}</td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">Status</th>
                                        <td>
                                            {selectedRowData.status ? (
                                                <span className="badge badge-light-success">
                                                    Active
                                                </span>
                                            ) : (
                                                <span className="badge badge-light-danger">
                                                    Inactive
                                                </span>
                                            )}
                                        </td>
                                    </tr>

                                    <tr className="border-bottom-primary">
                                        <th scope="row">Group G-L Type</th>
                                        <td>
                                            {
                                                selectedRowData.group_general_ledger_type
                                            }
                                        </td>
                                    </tr>

                                    {/* <tr className="border-bottom-secondary">
                                        <th scope="row">Date Created</th>
                                        <td>
                                            {formatDateWithTimeZone(
                                                selectedRowData.created_at
                                            )}
                                        </td>
                                    </tr> */}
                                </tbody>
                            </Table>
                        </>
                    )}
                </ViewModal>
            </Container>
        </Fragment>
    );
};

export default GroupGeneralLedgerNumber;

export const StatusRow = (row: (typeof data)[0]) => {
    return row.status ? (
        <span className="badge badge-light-success">Active</span>
    ) : (
        <span className="badge badge-light-danger">Inactive</span>
    );
};
