import React, { Fragment, useContext, useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Card, Table, CardBody } from 'reactstrap';
import { Breadcrumbs } from '../../../../../AbstractElements';
import { DateCreated, DateModified, DeleteSuccess, Description, Status, CapacityType, Capacity} from '../../../../../Constant';
import HeaderCard from '../../../../Common/Component/HeaderCard';
import CustomizerContext from '../../../../../_helper/Customizer';
import { CapacityApi, CapacityTypeApi } from '../../../../../api';
import { toast } from 'react-toastify';
import DataTableServerFetchComponent from '../../../../Common/Component/DataTableServerFetchComponent';
import ViewModal from '../../../../Common/Component/ViewModal';
import { base_form_fields, createDropdownField } from '../../../../../Data/FormField/PublicData';
import { formatDateWithTimeZone } from '../../../../../_helper/dateHelper';
import useAxiosPrivate from '../../../../../Hooks/useAxiosPrivate';
import useAuth from '../../../../../Hooks/useAuth';

const CList = () => {
  const { layoutURL } = useContext(CustomizerContext);
  const axiosPrivate = useAxiosPrivate()
  const { userOrganisationId } = useAuth();
  const [allData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [formFields, setFormFields] = useState(base_form_fields(`${Capacity}`));
  const [isNewFieldAdded, setIsNewFieldAdded] = useState(false);

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  
  const getAllCapacityData = async (page =1) => {
      try {
            setIsLoading(true);
            await axiosPrivate.get(`${CapacityApi}?organisation_id=${userOrganisationId}&page=${page}&size=${perPage}`).then((resp) => {
              setAllData(resp.data.items);
              setTotalRows(resp.data.total);
          });
      } catch (error) {
          toast.error(error.response.data.detail)
          //setIsLoading(false);
      } finally {
        // Set loading to false after data is fetched (regardless of success or failure)
        setIsLoading(false);
      }
  };

  const capacityTypeDropdownField = createDropdownField(axiosPrivate,
    'capacity_type_id',
    'capacity_type_id',
    `${CapacityType}`,
    `${CapacityTypeApi}?organisation_id=${userOrganisationId}`,
    '',12
  );


  useEffect(() => {

    const fetchData = async () => {
      try {
        if (!isNewFieldAdded) {
          const updatedFields = [capacityTypeDropdownField, ...formFields];
          const indexOfName = updatedFields.findIndex((formField) => formField.id === 'capacity_type_id');

          await updatedFields[0].fetchOptions();
          //await updatedFields[1].fetchOptions();
          //await updatedFields[2].fetchOptions();
          await updatedFields[indexOfName].fetchOptions();
          
          setFormFields(updatedFields);
          setIsNewFieldAdded(true);
        }
      } catch (error) {
        //console.error('Error fetching options:', error);
      }
    };

    fetchData();

  }, [formFields, isNewFieldAdded, capacityTypeDropdownField]);

  useEffect(() => {
    const abortController = new AbortController();
    getAllCapacityData();

    return () => {
      abortController.abort();
    };
  }, [setAllData]);

  const handleView = (row) => {
    setViewModalOpen(true);
    setEditMode(false);
    setSelectedRowData(row);
  };

  const handleEdit = (row) => {
    // Handle the Edit action
    setViewModalOpen(true);
    setEditMode(true);
    row.name = row.capacity
    setSelectedRowData(row);
  };

  const handleEditRec =  async (id, editRecord) => {
    //console.log(editRecord)
    //return false
    const editRecordTemp = {
        organisation_id: userOrganisationId,
        capacity_type_id: editRecord.capacity_type_id,
        capacity: editRecord.name,
        description: editRecord.description,
        is_active: editRecord.is_active,
    };
    
    try {
        await axiosPrivate.put(`${CapacityApi}/${id}`, editRecordTemp).then((resp) => {
          const updatedData = allData.map((row) =>
            row.id === selectedRowData.id ? { ...row, ...resp.data } : row
          );

          setAllData(updatedData);

          toast.success(`${resp.data.name} is successfully updated.`)
          handleCloseModal()
        });
    } catch (error) {
        if (error.response && error.response.status === 422) {
            toast.error('Validation Errors');
          } else if (error.response && error.response.status === 400) {
            toast.error(error.response.data.detail);
          } else {
            toast.error('Form submission error');
          }
        throw error;
    }
  };

  const memoizedHandleEdit = useMemo(() => handleEditRec, [allData, selectedRowData]);

  const handleCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleDelete = async (row) => {
    // Prompt for confirmation
    const shouldDelete = window.confirm('Are you sure you want to delete this record?');

    if (!shouldDelete) {
      return; // Do nothing if the user cancels the deletion
    }

    try {
      const response = await axiosPrivate.delete(`${CapacityApi}/${row.id}`);

      if (response.status === 204) {
        setAllData((prevData) => prevData.filter((item) => item.id !== row.id));
        toast.success(DeleteSuccess);
      } else {
        toast.error('Delete request failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error deleting record:', error);
    }
  };

  const addBtn = `${process.env.PUBLIC_URL}/sales/settings/capacities/create/${layoutURL}`;
  
  const tableColumns = [
    {
        name: `${Capacity}`,
        selector: row => `${row.capacity}`,
        sortable: true,
        center: false,
    },
    {
      name: `${CapacityType}`,
      selector: row => `${row.capacity_type.name}`,
      sortable: true,
      center: false,
  },
    {
      name: 'Status',
      selector: row => row.is_active,
      sortable: true,
      center: false,
      cell: row => (row.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>)
    },
    {
      name: `${DateCreated}`,
      selector: row => `${formatDateWithTimeZone(row.created_at)}`,
      sortable: true,
      center: false,
    },
    {
      name: `${DateModified}`,
      selector: row => `${formatDateWithTimeZone(row.updated_at)}`,
      sortable: true,
      center: false,
    },
  ];

  const handlePageChange = page => {
    getAllCapacityData(page)
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setIsLoading(true);
    const response = await axiosPrivate.get(`${CapacityApi}?organisation_id=${userOrganisationId}&page=${page}&size=${perPage}`);
    setAllData(response.data.items);
    setPerPage(newPerPage);
    setIsLoading(false);
  };

  return (
    <Fragment>
      <Breadcrumbs parent="Sales" title="Capacity" mainTitle="Capacity" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <HeaderCard title="List" btnUrl={addBtn} />
              <CardBody>
                <DataTableServerFetchComponent
                  tabledata={allData}
                  tableColumns={tableColumns}
                  isLoading={isLoading}
                  onView={handleView}
                  onDelete={handleDelete}
                  onEdit={handleEdit}

                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>

        <ViewModal 
          isOpen={viewModalOpen} 
          toggle={() => setViewModalOpen(!viewModalOpen)} 
          rowData={selectedRowData} 
          formFields={formFields}
          editMode={editMode}
          onEdit={memoizedHandleEdit}
        >
          {selectedRowData && (
            <>
            <Table>
                <tbody>
                <tr className='border-bottom-primary'>
                    <th scope='row'>{Capacity}</th>
                    <td>{selectedRowData.capacity}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{Description}</th>
                    <td>{selectedRowData.description}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{Status}</th>
                    <td>{selectedRowData.is_active ? <span className="badge badge-light-success">Active</span> : <span className="badge badge-light-danger">Inactive</span>}</td>
                  </tr>
                  <tr className='border-bottom-secondary'>
                    <th scope='row'>{DateCreated}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.created_at)}</td>
                  </tr>
                  <tr className='border-bottom-primary'>
                    <th scope='row'>{DateModified}</th>
                    <td>{formatDateWithTimeZone(selectedRowData.updated_at)}</td>
                  </tr>
                </tbody>
              </Table>
            </>
          )}


          
        </ViewModal>

        {/* <ViewModal isOpen={viewModalOpen} toggle={() => setViewModalOpen(!viewModalOpen)} onEdit={handleEdit}>
            
          <p>ID: 456</p>
          <p>Name: Jane Smith</p>
        </ViewModal> */}
      </Container>
    </Fragment>
  );
};

export default CList;