import { ProductName, ProductDescription, PriceAmount, ProductImage } from "../../../../Constant"


export const product_form_fields = (LabelName, colSize) => ([
    {
      id: 'name',
      name: 'name',
      label: `${ProductName}`,
      type: 'text',
      value: '',
      required: true,
      colSize: colSize ? `${colSize}` : 12,
    },
    {
      id: 'description',
      name: 'description',
      label: `${ProductDescription}`,
      type: 'textarea',
      value: '',
      required: true,
      colSize: colSize ? `${colSize}` : 12,
    },
    {
      id: 'tariff_price',
      name: 'tariff_price',
      label: `${PriceAmount}`,
      type: 'number',
      value: '',
      required: true,
      colSize: colSize ? `${colSize}` : 12,
    },
   
    {
      id: 'product_image',
      name: 'product_image',
      label: `${ProductImage}`,
      type: 'file',
      value: '',
      required: false,
      colSize: colSize ? `${colSize}` : 12,
    },
  ]);