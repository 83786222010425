import { useContext, useLayoutEffect, useState } from "react";
import CustomizerContext from "../../../../../_helper/Customizer";
import {
    ExpenseClass,
    Description,
    ClassName,
    ExpenseGroup,
} from "../../../../../Constant";
import { createDropdownField } from "../../../../../Data/FormField/PublicData";
import { RegionalContinentApi, RegionalCountryApi } from "../../../../../api";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";

export default function useExpenseClassController() {
    const axiosPrivate = useAxiosPrivate()
    const formTitle = ExpenseClass;

    const [formFields, setFormFields] = useState<any[]>([
        {
            id: "class_name",
            name: "class_name",
            label: ClassName,
            type: "text",
            value: "",
            required: true,
            colSize: 6,
        },

        {
            id: "description",
            name: "description",
            label: Description,
            type: "textarea",
            value: "",
            required: true,
            colSize: 6,
        },
    ]);

    const expenseCategory = createDropdownField(axiosPrivate,
        "expense_category",
        "expense_category",
        ExpenseGroup,
        `${RegionalContinentApi}`,
        "",
        6
    );
    expenseCategory.onChange = async (id, fields) => {
        await expenseGroup.fetchOptions();
        updateFields();
    };

    const expenseGroup = createDropdownField(axiosPrivate,
        "expense_group",
        "expense_group",
        ExpenseClass,
        `${RegionalCountryApi}`,
        "",
        6
    );

    // ?? Call on every dependent change
    async function updateFields() {
        const updatedFields = [...formFields, expenseCategory, expenseGroup];
        await expenseCategory.fetchOptions();
        setFormFields(updatedFields);
    }

    useLayoutEffect(() => {
        //?? Updates all fields once component is mounted
        updateFields();
    }, []);

    const { layoutURL } = useContext(CustomizerContext);
    const backBtn = `${process.env.PUBLIC_URL}/finance/settings/expense-class/${layoutURL}`;

    function onSubmit(data: any) {
        //console.log(data);
    }

    return { formTitle, formFields, backBtn, onSubmit };
}
