import { useContext, useMemo, useState } from "react";
import dataJson from "./group-general-ledger-class-data.json";
import { StatusRow } from "./GroupGeneralLedgerClassList";
import CustomizerContext from "../../../../../_helper/Customizer";

export default function useGroupGeneralLedgerClassListController() {
    const tableColumns = [
        {
            name: `Name`,
            selector: (row: (typeof data)[0]) => `${row.name}`,
            sortable: true,
            center: false,
        },

        {
            name: `Description`,
            selector: (row: (typeof data)[0]) => `${row.description}`,
            sortable: false,
            center: false,
        },

        {
            name: `Status`,
            selector: (row: (typeof data)[0]) => `${row.status}`,
            sortable: true,
            center: true,
            cell: (row: (typeof data)[0]) => StatusRow(row),
        },

        {
            name: `G-L Group`,
            selector: (row: (typeof data)[0]) => `${row.general_ledger_group}`,
            sortable: true,
            center: false,
        },

        // {
        //     name: ``,
        //     selector: (row) => `${formatDateWithTimeZone(row.created_at)}`,
        //     sortable: true,
        //     center: false,
        // },
        // {
        //     name: `${DateModified}`,
        //     selector: (row) => `${formatDateWithTimeZone(row.updated_at)}`,
        //     sortable: true,
        //     center: false,
        // },
    ];
    const { layoutURL } = useContext(CustomizerContext);
    const newBtn = `${process.env.PUBLIC_URL}/finance/settings/group-general-ledger-class/create/${layoutURL}`;
    // const [isLoading, setIsLoading] = useState(true);
    const [data] = useState(dataJson);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<
        (typeof data)[0] | null
    >(null);
    const [editMode, setEditMode] = useState(false);

    const handleEdit = (row: (typeof data)[0]) => {
        setViewModalOpen(true);
        setEditMode(true);
        setSelectedRowData(row);
    };

    const handleView = (row: (typeof data)[0]) => {
        setViewModalOpen(true);
        setEditMode(false);
        setSelectedRowData(row);
    };

    const handleEditRec = async (
        id: number | string,
        editRecord: (typeof data)[0]
    ) => {
      
    };

    const memoizedHandleEdit = useMemo(
        () => handleEditRec,
        [data, selectedRowData]
    );

    return {
        data,
        tableColumns,
        viewModalOpen,
        selectedRowData,
        setViewModalOpen,
        editMode,
        newBtn,
        handleView,
        handleEdit,
        memoizedHandleEdit,
    };
}
