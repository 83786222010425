import { Title, Description, Status, NumberCode, Code, Sector , 
  RegionalContinent, Capital, CurrencyCode, Nationality, Slogan, PhoneCode,
  Flag, RegionalCountry, AreaCode, Zone, Headquarter, RegionalState, RegionalLga, BankType,
  IrsName,
  IrsShortName,
  StateCode,
  BankAccount,
  PaymentCode,
  Bank,
  Pfc,
  Pfa,
  FundCode,
  FundName,
  Upload,
  Primary,
  SubSector,
  Ward,
  ContactType,
  Salutation,
  Gender,
  Alias,
  MaritalStatus,
  Relationship,
  MedicalAilment,
  BloodGroup,
  Genotype,
  AlphabetCode,
  CurrencyName,
  Decimal,
  Symbol,
  Religion,
  CountryCode,
  ISO2,
  LgaCode,
  Color,
  Visibility,
  Icon,
  ExternalUrl,
  CustomText} from '../../../Constant';
import axios from "../../../api/axios";
import { SectorApi, RegionalContinentApi, RegionalCountryApi, ZoneApi, RegionalStateApi, RegionalLgaApi, BankTypeApi, BankApi, PfcApi, PfaApi, SubSectorApi, WardApi, ContactTypeApi } from '../../../api';
//import useAxiosPrivate from '../../../Hooks/useAxiosPrivate';
import FetchDropDownFieldOptions from '../FetchDropDownFieldOptions';
import FetchDynamicDropDownFieldOptions from '../FetchDynamicDropDownFieldOptions';

//const axios = useAxiosPrivate()

export const ContactTypeVal = {
  Individual: 1,
  Corporate: 2
};

export const base_form_fields3 = (LabelName, colSize) => ([
  {
    id: 'name',
    name: 'name',
    label: LabelName ? `${LabelName}` : `${Title}`,
    type: 'text',
    value: '',
    required: true,
    colSize: colSize ? `${colSize}` : 12,
  },
])
export const base_form_fields_remittance = (LabelName, colSize) => ([
  {
    id: ' Tax ID Number',
    name: ' Tax ID Number',
    label: LabelName ? `${LabelName}` : "Tax ID Number",
    type: 'text',
    value: '',
    required: true,
    colSize: colSize ? `${colSize}` : 12,
  },
  {
    id: 'VAT ID Number',
    name: 'VAT ID Number',
    label: LabelName ? `${LabelName}` : "VAT ID Number",
    type: 'text',
    value: '',
    required: true,
    colSize: colSize ? `${colSize}` : 12,
  },
  {
    id: ' PenCom Code',
    name: ' PenCom Code',
    label: LabelName ? `${LabelName}` : "PenCom Code",
    type: 'text',
    value: '',
    required: true,
    colSize: colSize ? `${colSize}` : 12,
  },
  {
    id: 'RSA Number',
    name: 'RSA Number',
    label: LabelName ? `${LabelName}` : "RSA Number",
    type: 'text',
    value: '',
    required: true,
    colSize: colSize ? `${colSize}` : 12,
  },
  {
    id: 'NHF Number',
    name: 'NHF Number',
    label: LabelName ? `${LabelName}` : "NHF Number",
    type: 'text',
    value: '',
    required: true,
    colSize: colSize ? `${colSize}` : 12,
  },

])

export const base_form_fields = (LabelName, colSize) => ([
    {
      id: 'name',
      name: 'name',
      label: LabelName ? `${LabelName}` : `${Title}`,
      type: 'text',
      value: '',
      required: true,
      colSize: colSize ? `${colSize}` : 12,
    },
    {
      id: 'description',
      name: 'description',
      label: `${Description}`,
      type: 'textarea',
      value: '',
      required: true,
      colSize: colSize ? `${colSize}` : 12,
    },
    {
      id: 'is_active',
      name: 'is_active',
      label: `${Status}`,
      type: 'switch',
      value: '1',
      required: false,
      colSize: colSize ? `${colSize}` : 12,
    },
]);

export const base_form_fields1 = [
  {
    id: 'name',
    name: 'name',
    label: `${Title}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'description',
    name: 'description',
    label: `${Description}`,
    type: 'textarea',
    value: '',
    required: true,
  },
  {
    id: 'is_active',
    name: 'is_active',
    label: `${Status}`,
    type: 'switch',
    value: '1',
    required: false,
  },
];

export const base_form_fields2 = (LabelName) => ([
  {
    id: 'name',
    name: 'name',
    label: `${LabelName}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'is_active',
    name: 'is_active',
    label: `${Status}`,
    type: 'switch',
    value: '1',
    required: false,
  },
]);  

export const file_field = {
  id: 'file',
  name: 'file',
  label: `${Upload}`,
  type: 'file',
  value: '',
  required: true,
};

export const primary_field = {
  id: 'is_primary',
  name: 'is_primary',
  label: `${Primary}`,
  type: 'switch',
  value: '',
  required: false,
};

export const short_name_field = (LabelName, colSize) => ({
  id: 'short_name',
  name: 'short_name',
  label: LabelName ? `${LabelName}` : `${Alias}`,
  type: 'text',
  value: '',
  required: true,
  colSize: colSize ? `${colSize}` : 12,
});

export const code_field = (LabelName, IType) => ({
    id: 'code',
    name: 'code',
    label: LabelName ? `${LabelName}` : `${Code}`,
    type: IType ? `${IType}` : 'text',
    value: '',
    required: true,
});

export const dynamic_field = (frmName, LabelName, IType, colSize, requiredStatus) => ({
  id: frmName ? `${frmName}` : `${CustomText}`,
  name: frmName ? `${frmName}` : `${CustomText}`,
  label: LabelName ? `${LabelName}` : `${Code}`,
  type: IType ? `${IType}` : 'text',
  value: '',
  required: typeof requiredStatus === 'boolean' ? requiredStatus : true,
  colSize: colSize ? `${colSize}` : 12,
});

export const visibility_field = {
  id: 'visibility',
  name: 'visibility',
  label: `${Visibility}`,
  type: 'switch',
  value: '',
  required: false,
};

export const icon_field = (LabelName, IType, colSize) => ({
  id: 'icon',
  name: 'icon',
  label: LabelName ? `${LabelName}` : `${Icon}`,
  type: IType ? `${IType}` : 'text',
  value: '',
  required: false,
  colSize: colSize ? `${colSize}` : 12,
});

export const external_url_field = (LabelName, IType, colSize) => ({
  id: 'external_url',
  name: 'external_url',
  label: LabelName ? `${LabelName}` : `${ExternalUrl}`,
  type: IType ? `${IType}` : 'url',
  value: '',
  required: false,
  colSize: colSize ? `${colSize}` : 12,
});

// color
export const color_field = (LabelName, IType) => ({
  id: 'color',
  name: 'color',
  label: LabelName ? `${LabelName}` : `${Color}`,
  type: IType ? `${IType}` : 'color',
  value: '',
  required: true,
});

export const currency_number_field = [
  {
    id: 'code',
    name: 'code',
    label: `${AlphabetCode}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'number_code',
    name: 'number_code',
    label: `${NumberCode}`,
    type: 'number',
    value: '',
    required: true,
  },
  {
    id: 'name',
    name: 'name',
    label: `${CurrencyName}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'decimal',
    name: 'decimal',
    label: `${Decimal}`,
    type: 'number',
    value: '',
    required: true,
  },
  {
    id: 'symbol',
    name: 'symbol',
    label: `${Symbol}`,
    type: 'text',
    value: '',
    required: false,
  },
  {
    id: 'is_active',
    name: 'is_active',
    label: `${Status}`,
    type: 'switch',
    value: '1',
    required: false,
  },
];

export const salutation_form_fields = [
  {
    id: 'name',
    name: 'name',
    label: `${Salutation}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'is_active',
    name: 'is_active',
    label: `${Status}`,
    type: 'switch',
    value: '1',
    required: false,
  },
];

export const gender_form_fields = [
  {
    id: 'name',
    name: 'name',
    label: `${Gender}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'short_name',
    name: 'short_name',
    label: `${Alias}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'is_active',
    name: 'is_active',
    label: `${Status}`,
    type: 'switch',
    value: '1',
    required: false,
  },
];

export const marital_status_form_fields = [
  {
    id: 'name',
    name: 'name',
    label: `${MaritalStatus}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'short_name',
    name: 'short_name',
    label: `${Alias}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'is_active',
    name: 'is_active',
    label: `${Status}`,
    type: 'switch',
    value: '1',
    required: false,
  },
];

export const relationship_form_fields = [
  {
    id: 'name',
    name: 'name',
    label: `${Relationship}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'is_active',
    name: 'is_active',
    label: `${Status}`,
    type: 'switch',
    value: '1',
    required: false,
  },
];

export const medical_ailemnt_form_fields = [
  {
    id: 'name',
    name: 'name',
    label: `${MedicalAilment}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'description',
    name: 'description',
    label: `${Description}`,
    type: 'textarea',
    value: '',
    required: true,
  },
  {
    id: 'is_active',
    name: 'is_active',
    label: `${Status}`,
    type: 'switch',
    value: '1',
    required: false,
  },
];

export const blood_group_form_fields = [
  {
    id: 'name',
    name: 'name',
    label: `${BloodGroup}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'is_active',
    name: 'is_active',
    label: `${Status}`,
    type: 'switch',
    value: '1',
    required: false,
  },
];

export const genotype_form_fields = [
  {
    id: 'name',
    name: 'name',
    label: `${Genotype}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'is_active',
    name: 'is_active',
    label: `${Status}`,
    type: 'switch',
    value: '1',
    required: false,
  },
];

export const religion_form_fields = [
  {
    id: 'name',
    name: 'name',
    label: `${Religion}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'is_active',
    name: 'is_active',
    label: `${Status}`,
    type: 'switch',
    value: '1',
    required: false,
  },
];

/* const FetchDropDownFieldOptions = async (ApiEndPoint) => { 
  try {
    const axios = useAxiosPrivate();
    console.log(`Endpoint: ${ApiEndPoint}`)
    const response = await axios.get(ApiEndPoint);
    return response.data.map(item => ({ value: item.id, label: item.name }));
  } catch (error) {
    console.error('Error fetching contact type options:', error);
    return [];
  }
};  */

/* export const contact_type_dropdown_field = {
  id: 'contact_type_id',
  name: 'contact_type_id',
  label: `${ContactType}`,
  type: 'select',
  required: true,
  options: [], 
  async fetchOptions() {
    const options = []; //await FetchDropDownFieldOptions(ContactTypeApi);
    this.options = options;
    try {
      const axios = useAxiosPrivate();
      const response = await axios.get(ContactTypeApi);
      
      this.options = response.data.map(item => ({ value: item.id, label: item.name }));
    } catch (error) {
      console.error('Error fetching contact type options:', error);
    } 
  },
  // onChange: (value) => console.log(value), // handle the change event here
}; */

/* export const sector_dropdown_field = {
  id: 'sector_id',
  name: 'sector_id',
  label: `${Sector}`,
  type: 'select',
  required: true,
  options: [], 
  async fetchOptions() {
    const options = await FetchDropDownFieldOptions(SectorApi);
    this.options = options;
     try {
      const response = await axios.get(SectorApi);
      
      this.options = response.data.map(item => ({ value: item.id, label: item.name }));
    } catch (error) {
      console.error('Error fetching sector options:', error);
    } 
  },
  // onChange: (value) => console.log(value), // handle the change event here
}; */

/* export const sub_sector_dropdown_field = {
  id: 'sub_sector_id',
  name: 'sub_sector_id',
  label: `${SubSector}`,
  type: 'select',
  required: true,
  options: [], 
  async fetchOptions() {
    const options = await FetchDropDownFieldOptions(SubSectorApi);
    this.options = options;
     try {
      const response = await axios.get(SubSectorApi);
      
      this.options = response.data.map(item => ({ value: item.id, label: item.name }));
    } catch (error) {
      console.error('Error fetching sub sector options:', error);
    } 
  },
  // onChange: (value) => console.log(value), // handle the change event here
}; */

export const continent_dropdown_field = {
  id: 'regional_continent_id',
  name: 'regional_continent_id',
  label: `${RegionalContinent}`,
  type: 'select',
  required: true,
  options: [], 
  async fetchOptions() { 
    const options = await FetchDropDownFieldOptions(RegionalContinentApi);
    this.options = options;
   /*  try {
      const response = await axios.get(RegionalContinentApi);
      
      this.options = response.data.map(item => ({ value: item.id, label: item.name }));
    } catch (error) {
      console.error('Error fetching continent options:', error);
    } */
  },
  // onChange: (value) => console.log(value), // handle the change event here
};

export const country_dropdown_field = {
  id: 'regional_country_id',
  name: 'regional_country_id',
  label: `${RegionalCountry}`,
  type: 'select',
  required: true,
  options: [], 
  async fetchOptions() {
    const options = await FetchDropDownFieldOptions(RegionalCountryApi);
    this.options = options;
    /* try {
      const response = await axios.get(RegionalCountryApi);
      
      this.options = response.data.map(item => ({ value: item.id, label: item.name }));
    } catch (error) {
      console.error('Error fetching country options:', error);
    } */
  },
  // onChange: (value) => console.log(value), // handle the change event here
};

export const zone_dropdown_field = {
  id: 'zone_id',
  name: 'zone_id',
  label: `${Zone}`,
  type: 'select',
  required: true,
  options: [], 
  async fetchOptions() {
    const options = await FetchDropDownFieldOptions(ZoneApi);
    this.options = options;
    /* try {
      const response = await axios.get(ZoneApi);
      
      this.options = response.data.map(item => ({ value: item.id, label: item.name }));
    } catch (error) {
      console.error('Error fetching zone options:', error);
    } */
  },
  // onChange: (value) => console.log(value), // handle the change event here
};

export const state_dropdown_field = {
  id: 'regional_state_id',
  name: 'regional_state_id',
  label: `${RegionalState}`,
  type: 'select',
  required: true,
  options: [], 
  async fetchOptions() {
    const options = await FetchDropDownFieldOptions(RegionalStateApi);
    this.options = options;
    /* try {
      const response = await axios.get(RegionalStateApi);
      
      this.options = response.data.map(item => ({ value: item.id, label: item.name }));
    } catch (error) {
      console.error('Error fetching state options:', error);
    } */
  },
  // onChange: (value) => console.log(value), // handle the change event here
};

export const lga_dropdown_field = {
  id: 'regional_lga_id',
  name: 'regional_lga_id',
  label: `${RegionalLga}`,
  type: 'select',
  required: true,
  options: [], 
  async fetchOptions() {
    const options = await FetchDropDownFieldOptions(RegionalLgaApi);
    this.options = options;
    /* try {
      const response = await axios.get(RegionalLgaApi);
      
      this.options = response.data.map(item => ({ value: item.id, label: item.name }));
    } catch (error) {
      console.error('Error fetching lga options:', error);
    } */
  },
  // onChange: (value) => console.log(value), // handle the change event here
};

export const ward_dropdown_field = {
  id: 'ward_id',
  name: 'ward_id',
  label: `${Ward}`,
  type: 'select',
  required: true,
  options: [], 
  async fetchOptions() {
    const options = await FetchDropDownFieldOptions(WardApi);
    this.options = options;
    /* try {
      const response = await axios.get(WardApi);
      
      this.options = response.data.map(item => ({ value: item.id, label: item.name }));
    } catch (error) {
      console.error('Error fetching ward options:', error);
    } */
  },
  // onChange: (value) => console.log(value), // handle the change event here
};

export const bank_type_dropdown_field = {
  id: 'bank_type_id',
  name: 'bank_type_id',
  label: `${BankType}`,
  type: 'select',
  required: true,
  options: [], 
  async fetchOptions() {
    const options = await FetchDropDownFieldOptions(BankTypeApi);
    this.options = options;
    /* try {
      const response = await axios.get(BankTypeApi);
      
      this.options = response.data.map(item => ({ value: item.id, label: item.name }));
    } catch (error) {
      console.error('Error fetching sector options:', error);
    } */
  },
  // onChange: (value) => console.log(value), // handle the change event here
};

export const bank_dropdown_field = {
  id: 'bank_id',
  name: 'bank_id',
  label: `${Bank}`,
  type: 'select',
  required: true,
  options: [], 
  async fetchOptions() {
    const options = await FetchDropDownFieldOptions(BankApi);
    this.options = options;
    /* try {
      const response = await axios.get(BankApi);
      
      this.options = response.data.map(item => ({ value: item.id, label: item.name }));
    } catch (error) {
      console.error('Error fetching sector options:', error);
    } */
  },
  // onChange: (value) => console.log(value), // handle the change event here
};

export const pfc_dropdown_field = {
  id: 'pfc_id',
  name: 'pfc_id',
  label: `${Pfc}`,
  type: 'select',
  required: true,
  options: [], 
  async fetchOptions() {
    const options = await FetchDropDownFieldOptions(PfcApi);
    this.options = options;
    /* try {
      const response = await axios.get(PfcApi);
      
      this.options = response.data.map(item => ({ value: item.id, label: item.name }));
    } catch (error) {
      console.error('Error fetching sector options:', error);
    } */
  },
  // onChange: (value) => console.log(value), // handle the change event here
};

export const pfa_dropdown_field = {
  id: 'pfa_id',
  name: 'pfa_id',
  label: `${Pfa}`,
  type: 'select',
  required: true,
  options: [], 
  async fetchOptions() {
    const options = await FetchDropDownFieldOptions(PfaApi);
    this.options = options;
    /* try {
      const response = await axios.get(PfaApi);
      
      this.options = response.data.map(item => ({ value: item.id, label: item.name }));
    } catch (error) {
      console.error('Error fetching sector options:', error);
    } */
  },
  // onChange: (value) => console.log(value), // handle the change event here
  /* onChange: (value, setFieldValue, dependentField, dependentFieldOptionsEndpoint, sectorValue) => {
    setFieldValue(dependentField.name, ''); // Reset dependent field when bank changes
    dependentField.fetchOptions(value, dependentFieldOptionsEndpoint, sectorValue);
  }, */
};

export const country_fields = [
  {
    id: 'capital',
    name: 'capital',
    label: `${Capital}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'country_code',
    name: 'country_code',
    label: `${CountryCode}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'phone_code',
    name: 'phone_code',
    label: `${PhoneCode}`,
    type: 'number',
    value: '',
    required: true,
  },
  {
    id: 'currency_code',
    name: 'currency_code',
    label: `${CurrencyCode}`,
    type: 'number',
    value: '',
    required: true,
  },
  {
    id: 'iso2',
    name: 'iso2',
    label: `${ISO2}`,
    type: 'text',
    value: '',
    required: false,
  },
  {
    id: 'nationality',
    name: 'nationality',
    label: `${Nationality}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'slogan',
    name: 'slogan',
    label: `${Slogan}`,
    type: 'text',
    value: '',
    required: false,
  },
  {
    id: 'flag',
    name: 'flag',
    label: `${Flag}`,
    type: 'text',
    value: '',
    required: false,
  },
];

export const state_fields = [
  {
    id: 'code',
    name: 'code',
    label: `${StateCode}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'capital',
    name: 'capital',
    label: `${Capital}`,
    type: 'text',
    value: '',
    required: true,
  },
  /* {
    id: 'area_code',
    name: 'area_code',
    label: `${AreaCode}`,
    type: 'text',
    value: '',
    required: true,
  }, */
  {
    id: 'slogan',
    name: 'slogan',
    label: `${Slogan}`,
    type: 'text',
    value: '',
    required: false,
  },
];

export const lga_fields = [
  {
    id: 'code',
    name: 'code',
    label: `${LgaCode}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'headquarter',
    name: 'headquarter',
    label: `${Headquarter}`,
    type: 'text',
    value: '',
    required: true,
  },
];

export const tax_authority_fields = [
  {
    id: 'bank_account',
    name: 'bank_account',
    label: `${BankAccount}`,
    type: 'number',
    value: '',
    required: true,
  },
  {
    id: 'state_code',
    name: 'state_code',
    label: `${StateCode}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'irs_name',
    name: 'irs_name',
    label: `${IrsName}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'irs_short_name',
    name: 'irs_short_name',
    label: `${IrsShortName}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'payment_code',
    name: 'payment_code',
    label: `${PaymentCode}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'description',
    name: 'description',
    label: `${Description}`,
    type: 'textarea',
    value: '',
    required: true,
  },
  {
    id: 'is_active',
    name: 'is_active',
    label: `${Status}`,
    type: 'switch',
    value: '1',
    required: false,
  },
];

export const pfa_bank_acc_fields = [
  {
    id: 'bank_account',
    name: 'bank_account',
    label: `${BankAccount}`,
    type: 'number',
    value: '',
    required: true,
  },
  {
    id: 'fund_code',
    name: 'fund_code',
    label: `${FundCode}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'fund_name',
    name: 'fund_name',
    label: `${FundName}`,
    type: 'text',
    value: '',
    required: true,
  },
  {
    id: 'description',
    name: 'description',
    label: `${Description}`,
    type: 'textarea',
    value: '',
    required: true,
  },
  {
    id: 'is_active',
    name: 'is_active',
    label: `${Status}`,
    type: 'switch',
    value: '1',
    required: false,
  },
];

/* const FetchDynamicDropDownFieldOptions = async (value, changeStatus = false, newApiEndpoint) => {
  try {
    const axioss = useAxiosPrivate();
    this.loading = true;
    this.onChangeStatus = changeStatus;
    
    const endPoint = newApiEndpoint ? newApiEndpoint : apiEndpoint + (value ? `?parentId=${value}` : '');
    const response = await axios.get(endPoint);
    //console.log(value);
    //console.log(endPoint);
    
    const items = response.data.items || response.data;
    this.options = items.map(item => ({ 
      value: item.id, 
      label: itemKeyName ? this.getLabelFromKeys(item, itemKeyName) : item.name 
      //label: itemKeyName ? item[capacity_type].name : item.name 
    }));
    
    if (dependentField && typeof dependentField.fetchOptions === 'function' && value > 0) {
      dependentField.fetchOptions(value);
    }

  } catch (error) {
    this.options = [];
    //console.error(`Error fetching ${name} options:`, error);
  } finally {
    this.loading = false; // Set loading to false after fetching options
  }
} */

/* const FetchDynamicDropDownFields = async (apiEndpoint, newApiEndpoint, value, dependentField, itemKeyName) =>{
  try {
    const axios = useAxiosPrivate();
    
    const endPoint = newApiEndpoint ? newApiEndpoint : apiEndpoint + (value ? `?parentId=${value}` : '');
    const response = await axios.get(endPoint);
    //console.log(value);
    //console.log(endPoint);
    
    const items = response.data.items || response.data;
    const options = items.map(item => ({ 
      value: item.id, 
      label: itemKeyName ? this.getLabelFromKeys(item, itemKeyName) : item.name 
      //label: itemKeyName ? item[capacity_type].name : item.name 
    }));
    
    if (dependentField && typeof dependentField.fetchOptions === 'function' && value > 0) {
      dependentField.fetchOptions(value);
    }

    return options;
  } catch (error) {
    return [];
    //console.error(`Error fetching ${name} options:`, error);
  } 
} */
 
//===================================== Start Dynamic Dropdown =================================
export const createDropdownField = (axios, id, name, label, apiEndpoint, dependentField, colSize, requiredStatus, itemKeyName, disabled) => ({
  id,
  name,
  label,
  type: 'select',
  required: typeof requiredStatus === 'boolean' ? requiredStatus : true,
  disabled: typeof disabled === 'boolean' ? disabled : false,
  //required: requiredStatus ? `${requiredStatus}` : false,
  options: [],
  loading: false,
  onChangeStatus: false,
  updatedFields: [],
  colSize: colSize ? `${colSize}` : 6,
  async fetchOptions(value, changeStatus = false, newApiEndpoint) {
    this.loading = true;
    this.onChangeStatus = changeStatus;
  
    this.options = await FetchDynamicDropDownFieldOptions(
      axios, 
      apiEndpoint,
      newApiEndpoint,
      value,
      dependentField,
      itemKeyName,
    );

    this.loading = false; 

    /* try {
      const axioss = useAxiosPrivate();
      this.loading = true;
      this.onChangeStatus = changeStatus;
      
      const endPoint = newApiEndpoint ? newApiEndpoint : apiEndpoint + (value ? `?parentId=${value}` : '');
      const response = await axios.get(endPoint);
      //console.log(value);
      //console.log(endPoint);
      
      const items = response.data.items || response.data;
      this.options = items.map(item => ({ 
        value: item.id, 
        label: itemKeyName ? this.getLabelFromKeys(item, itemKeyName) : item.name 
        //label: itemKeyName ? item[capacity_type].name : item.name 
      }));
      
      if (dependentField && typeof dependentField.fetchOptions === 'function' && value > 0) {
        dependentField.fetchOptions(value);
      }

    } catch (error) {
      this.options = [];
      //console.error(`Error fetching ${name} options:`, error);
    } finally {
      this.loading = false; // Set loading to false after fetching options
    } */
  },
  /* getLabelFromKeys(item, itemKeyName) {
    const parseItemKeyName = itemKeyName.split(',').map(key => {
        const keys = key.trim().split('.');
        const originalKey = keys[0];
        const nestedKeys = keys.slice(1);
        return { originalKey, nestedKeys };
    });

    return parseItemKeyName.map(({ originalKey, nestedKeys }) => {
        // Check if the key has nested properties
        if (nestedKeys.length > 0) {
            // Retrieve the nested object
            let nestedObject = item[originalKey];
            // Iterate through nested keys to access nested properties
            for (const nestedKey of nestedKeys) {
                nestedObject = nestedObject[nestedKey];
            }
            return nestedObject;
        } else {
            // Return value of non-nested key
            return item[originalKey];
        }
    }).join(' ');
  }, */
  onChange: (value, updatedFields) => { 
    //this.onChangeStatus = true; 
    //this.updatedFields = updatedFields;
    //setFieldValue(dependentField.name, ''); // Reset dependent field when this field changes
    if (dependentField && typeof dependentField.fetchOptions === 'function') {
      dependentField.fetchOptions(value, true); 
    }
  },
});

export const handleDropdownOnChange = async (selectedContinentId, updatedFields) => {
  const countryField = updatedFields.find((field) => field.id === 'regional_country_id');
  let updatedFormFields = [...updatedFields]; // Create a copy of updatedFields array
  //console.log(`updatedFormFields b4: ${updatedFormFields}`)
  if (countryField) {
    await countryField.fetchOptions(selectedContinentId);

    // Update the value of continent_id and regional_country_id fields
    updatedFormFields = updatedFormFields.map((field) => {
      if (field.id === 'continent_id') {
        return { ...field, value: selectedContinentId };
      }
      if (field.id === 'regional_country_id') {
        return { ...field, value: 0 }; // Assuming 0 is the default value for country
      }
      return field;
    });
    //console.log(`updatedFormFields after: ${updatedFormFields}`)
  } 

  // Return the updated form fields
  return updatedFormFields;
};

export const createDropdownField2 = (id, name, label, apiEndpoint, dependentField, dependentFieldApiEndpoint) => {
  const field = {
    id,
    name,
    label,
    type: 'select',
    required: true,
    options: [],
    async FetchOptions(value) {
      try {
        //const axios = useAxiosPrivate();
        const response = await axios.get(apiEndpoint + (value ? `?parentId=${value}` : ''));
        this.options = response.data.map(item => ({ value: item.id, label: item.name }));
      } catch (error) {
        console.error(`Error fetching ${name} options:`, error);
      }
    },
    onChange: (value, setFieldValue) => {
      setFieldValue(dependentField.name, '');
      dependentField.fetchOptions(value);
    },
  };

  return { field, fetchOptions: field.fetchOptions };
};

/* export const continentDropdownField = createDropdownField(axiosPrivate,
  'continent_id',
  'continent_id',
  'Continent',
  'your/api/continents-endpoint',
  countryDropdownField,
  'your/api/countries-endpoint',
);

export const countryDropdownField = createDropdownField(axiosPrivate,
  'country_id',
  'country_id',
  'Country',
  'your/api/countries-endpoint',
  stateDropdownField,
  'your/api/states-endpoint',
);

export const stateDropdownField = createDropdownField(axiosPrivate,
  'state_id',
  'state_id',
  'State',
  'your/api/states-endpoint',
  null, // No dependent field for state
  null,
); */

//===================================== End Dynamic Dropdown =================================